<template>
  <div class="c-nav">
    <div class="c-nav-inner">
      <NavSlide v-if="isNavGamesInModile" class="c-nav-slide" />
      <router-link
        class="c-nav-logo-wrap"
        :to="$_localePath({ name: 'Index' })"
        @click.native="handleGoIndexClick">
        <i class="w-icon icon-logo-nav is-interactive"></i>
      </router-link>
      <transition name="fadein">
        <div
          v-if="showAddBookmark && !deferredInstallPrompt"
          class="w-tooltips font16 c-nav-bookmark"
          data-popper-placement="bottom-start">
          <w-icon name="icon_desktop_bookmark" :size="16" />
          <span>{{ $t('bookmark.add_bookmark_shortcut', [bookmarkShortcut]) }}</span>
          <w-button type="primary" text size="l"
            @click="handleNoMorePromptAddBookmark">{{ $t('global.no_more_prompts') }}</w-button>
          <div data-popper-arrow></div>
        </div>
        <div
          v-else-if="showAddBookmark && deferredInstallPrompt"
          class="c-nav-install">
          <div class="font18b c-nav-install-title">
            {{ $t('global.install_prompt') }}
            <w-button icon name="icon_close" :icon-size="24"
              @click="handleCloseInstall" />
          </div>
          <div class="c-nav-install-tips">
            <img src="~@/assets/images/logo.png" alt="LootBar logo" />
            <p class="font16r-min tips" v-html="$t('global.install_prompt_tips')"></p>
          </div>
          <div class="c-nav-install-actions">
            <w-button plain
              @click="handleCancelInstall">
              {{ $t('global.later') }}</w-button>
            <w-button type="primary"
              @click="handleInstall">
              {{ $t('global.confirm') }}</w-button>
          </div>
        </div>
      </transition>
      <transition name="fadein">
        <div
          v-if="showNotSupportPush"
          class="w-tooltips font16 c-nav-bookmark"
          data-popper-placement="bottom-start">
          <span>{{ $t('lootBar.browser_not_support_push') }}</span>
          <w-button type="primary" text size="l"
            @click="handleNoMorePromptAddBookmark">{{ $t('global.no_more_prompts') }}</w-button>
          <div data-popper-arrow></div>
        </div>
      </transition>
      <div class="c-nav-route">
        <router-link class="c-nav-route-item home"
          :to="$_localePath({ name: 'Index' })"
          :class="{'is-active': $route.name === 'Index'}"
          @click.native="handleGoIndexClick">
          {{ $t('global.home') }}
        </router-link>
        <NavGames v-if="!isNavGamesInModile" class="c-nav-route-item" />
        <a class="c-nav-route-item" :href="blogUrl">{{ $t('global.blog') }}</a>
        <HelpCenter class="c-nav-route-item" />
      </div>
      <div class="c-nav-search" :class="{'is-active': focusing}">
        <w-button
          class="c-nav-search-button"
          @click="handleSearchBtnClick">
          <w-icon name="icon_search" :size="20" />
        </w-button>
        <Autocomplete
          slot="reference"
          ref="searchInput"
          v-model="search"
          transparent
          class="c-nav-search-inner"
          :placeholder="$t('lootBar.placehorder')"
          :clearable="!!search"
          :suggestions="suggestions"
          :delay="500"
          :z-index="10000"
          @focus="handleSearchInputFocus"
          @blur="handleSearchInputBlur"
          @keypress.native.enter="handleSearch"
          @select="handleSearch"
          @action="handleSearchAction"
          @clear="handleSearchClear">
          <i slot="prefix" class="c-nav-search-icon" @click="handleSearchBtnClick"></i>
        </Autocomplete>
      </div>
      <!-- prefer -->
      <w-button class="c-nav-prefer"
        @click="isModalPreferShow = true;$logger('page_c2c_langcurrency_click')">
        {{ preferCurrency }}
        <span class="c-nav-prefer-gapline"></span>
        {{ preferLanguage }}
      </w-button>
      <div class="c-nav-account c-nav-account--desktop">
        <template v-if="loginStatus === LoginStatus.unknown">
          <w-spinner theme="normal-4" />
        </template>
        <template v-else-if="loginStatus === LoginStatus.unlogined
          || loginStatus === LoginStatus.unregistered">
          <w-button
            class="c-nav-sign" size="l"
            type="primary"
            @click="handleSignIn">
            {{ $t('global.sign_in') }}
          </w-button>
        </template>
        <template v-else-if="loginStatus === LoginStatus.logined">
          <UserInfo />
        </template>
      </div>

      <div
        v-if="submenuShow || submenuHover"
        class="c-nav-submenu w-popover__inner"
        :style="{left: `${submenuLeft}px` }"
        @mouseover="submenuHover=true"
        @mouseleave="submenuHover=false">
        <ul class="popover-menu">
          <template v-for="(item, index) in submenu">
            <a
              v-if="item.to" :key="index"
              :href="item.to" :target="item.target"
              @click="handleRouteTo(route)">
              <li>
                <span>{{ item.title }}</span>
                <w-icon name="icon_home_arrow" :size="16" />
              </li>
            </a>
            <a v-else-if="item.method" :key="index" href="javascript:void(0);"
              @click="item.method">
              <li @click="handleRouteTo(route)">
                <span>{{ item.title }}</span>
                <w-icon name="icon_home_arrow" :size="16" />
              </li>
            </a>
            <a v-else :key="index" href="javascript:void(0);">
              <li>
                <span>{{ item.title }}</span>
                <w-icon name="icon_home_arrow" :size="16" />
              </li>
              <div class="w-popover__inner">
                <ul class="popover-menu">
                  <a
                    v-for="(item, index) in item.submenu" :key="index"
                    :href="item.to" :target="item.target"
                    @click="handleRouteTo(route)">
                    <li>
                      <span>{{ item.title }}</span>
                      <w-icon name="icon_home_arrow" :size="16" />
                    </li>
                  </a>
                </ul>
              </div>
            </a>
          </template>
        </ul>
      </div>
    </div>
    <!-- 导航条指引或者提醒用户区域 -->
    <slot name="GuidanceZone"></slot>
    <slot></slot>
    <ModalPrefer
      :show="isModalPreferShow"
      @confirm="isModalPreferShow = false"
      @close="isModalPreferShow = false" />
  </div>
</template>

<script>
import localStorage from '@/utils/local-storage';
import { TASK, startupRunner } from '@/utils/startup';
import {
  GameType, TextType, BlogUrlEnumLanguage, BlogUrlEnum,
} from '@/enums';
import { getEndOfTime, isGoogleBot } from '@/utils';
import mixin from './Nav.mixin.c2c';

import UserInfo from './components/UserInfo.desktop.vue';
import NavGames from './components/Nav/Games.desktop.vue';
import HelpCenter from './components/Nav/HelpCenter.desktop.vue';
import NavSlide from './components/Nav/NavSlide.vue';

export default {
  components: {
    UserInfo,
    NavGames,
    HelpCenter,
    NavSlide,
  },
  mixins: [mixin],
  provide: {
    theme: 'dark',
  },
  data() {
    return {
      GameType,

      submenu: [],
      submenuLeft: 0,
      submenuShow: false,
      submenuHover: false,

      TEST_ENV: process.env.VUE_APP_CHECKOUT === 'test',

      noMorePromptAddBookmark: true,
      noMorePromptNotSupportPush: true,
      installPromptClosed: false,
      isNavGamesInModile: window.innerWidth < 1080,
    };
  },
  computed: {
    activityStyle() {
      if (this.activities.length === 0) return {};
      return {
        '--background-image': `url(${this.hoverActivity.extra.thumbnail_url})`,
        '--background-image-hover': `url(${this.hoverActivity.extra.hovered_thumbnail_url})`,
      };
    },
    blogUrl() {
      const blogUrl = BlogUrlEnumLanguage[this.textType] || BlogUrlEnumLanguage[TextType.en];
      const urlParamsData = {
        utm_source: this.$route.query.utm_source || '',
        utm_medium: this.$route.query.utm_medium || '',
        utm_campaign: this.$route.query.utm_campaign || '',
      };
      const url = new URL(blogUrl, BlogUrlEnum);
      const urlParams = new URLSearchParams(url.search);
      Object.keys(urlParamsData).forEach((key) => {
        if (urlParamsData[key]) {
          urlParams.set(key, urlParamsData[key]);
        }
      });
      url.search = urlParams.toString();
      return url.href;
    },
    routes() {
      const routes = [
        { to: '/market/all', title: `回market${this.$t('nav.market')}` },
      ];
      const submenu = [
        { to: '/faq', title: this.$t('nav.faq') },
        { to: '', method: this.handleFeedback, title: this.$t('nav.contact_support') },
        { to: '/static/user_agreement.html', title: this.$t('nav.terms_of_service'), target: '_blank' },
        { to: '/static/privacy_policy.html', title: this.$t('nav.privacy_policy'), target: '_blank' },
        { to: '/static/cookie_policy.html', title: this.$t('nav.cookie_policy'), target: '_blank' },
        {
          to: '',
          title: this.$t('nav.more_information'),
          submenu: [
            { to: '/static/shipping_policy.html', title: this.$t('nav.shipping_policy'), target: '_blank' },
            { to: '/static/about_us.html', title: this.$t('nav.about_us'), target: '_blank' },
            { to: '/static/contact_us.html', title: this.$t('nav.contact_us'), target: '_blank' },
            { to: '/static/law_jp.html', title: '特定商取引法に基づく', target: '_blank' },
          ],
        },
      ];
      routes.push({
        to: '/faq',
        title: this.$t('nav.help'),
        submenu,
      });
      return routes;
    },
    subgames() {
      return this.$store.getters.subgames;
    },
    subgame() {
      return this.$store.getters.subgame;
    },
    userSubgame() {
      return this.$store.getters.userSubgame;
    },
    // 页面title
    titleType() {
      return {
        [GameType.knivesout]: 'Knivesout',
      };
    },
    bookmarkShortcut() {
      const platform = navigator.userAgentData?.platform
        || navigator.platform || '';
      const isMacOS = platform.toUpperCase().indexOf('MAC') >= 0;
      return `${isMacOS ? 'CMD' : 'Ctrl'}+D`;
    },
    showAddBookmark() {
      const { isInStandalone } = this.$store.state;
      const { name } = this.$route;
      const isActiveRoute = ['Index', 'Topup'].includes(name);
      return !isInStandalone
        && !this.noMorePromptAddBookmark
        && !this.installPromptClosed
        && isActiveRoute;
    },
    deferredInstallPrompt() {
      return this.$store.state.deferredInstallPrompt;
    },
    showNotSupportPush() {
      const { name } = this.$route;
      const isActiveRoute = ['Seller.Order'].includes(name);
      const { isSupported } = this.$fcm;
      return !this.noMorePromptNotSupportPush && !isSupported && isActiveRoute;
    },
    showPwaInstall() {
      return this.showAddBookmark && this.deferredInstallPrompt;
    },
  },
  watch: {
    showPwaInstall: {
      handler(val) {
        if (val) this.$logger('c2c_pc_pwa_install_popup');
      },
      immediate: true,
    },
  },
  created() {
    this.$router.onReady(async () => {
      startupRunner.register(TASK.GuideInstallPwa, () => {
        if (isGoogleBot()) return Promise.reject();
        // 一天只显示一次安装提示
        const map = localStorage.get('no-more-prompts') || {};
        const cur = new Date().getTime();
        this.noMorePromptAddBookmark = cur <= map.addBookmark;
        this.noMorePromptNotSupportPush = cur <= map.notSupportPush;
        return Promise.resolve();
      });
    });
    window.addEventListener('resize', this.handleNavGamesInMobile);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleNavGamesInMobile);
  },
  methods: {
    handleRouteMouseover(e, { submenu }) {
      if (!submenu) this.submenu = [];
      const { left } = e.target.getBoundingClientRect();
      this.submenu = submenu;
      this.submenuLeft = left;
      this.submenuShow = true;
    },
    handleRouteMouseleave() {
      window.setTimeout(() => {
        this.submenuShow = false;
      });
    },
    handleNoMorePromptAddBookmark() {
      const map = localStorage.get('no-more-prompts') || {};
      // 一周后再次提示
      const offset = 1000 * 60 * 60 * 24 * 7;
      const timestamp = getEndOfTime(new Date().getTime() + offset, 'day');
      map.addBookmark = timestamp;
      localStorage.set('no-more-prompts', map);
      this.noMorePromptAddBookmark = true;
    },
    handleNoMorePromptNotSupportPush() {
      const map = localStorage.get('no-more-prompts') || {};
      const timestamp = getEndOfTime(new Date().getTime(), 'day');
      map.notSupportPush = timestamp;
      localStorage.set('no-more-prompts', map);
      this.noMorePromptNotSupportPush = true;
    },
    handleInstall() {
      this.$logger('c2c_pc_pwa_install_popup_install_click');
      this.installPromptClosed = true;
      this.$_handleInstall();
    },
    handleCancelInstall() {
      this.$logger('c2c_pc_pwa_install_popup_cancel_click');
      this.handleNoMorePromptAddBookmark();
    },
    handleCloseInstall() {
      this.$logger('c2c_pc_pwa_install_popup_close_click');
      this.handleNoMorePromptAddBookmark();
    },
    handleNavGamesInMobile() {
      if (window.innerWidth < 1080) {
        this.isNavGamesInModile = true;
      } else {
        this.isNavGamesInModile = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
@height: 80px;

.c-nav {
  height: @height;
  position: relative;
  z-index: 9999;

  &-inner {
    height: @height;
    width: 100%;
    display: flex;
    align-items: center;
    padding-right: 32px;
    background-color: var(--fu-grey2);
  }

  &-logo {
    &-wrap {
      flex: 0 0 82px;
      display: inline-block;
      line-height: 0;
    }
  }

  &-bookmark {
    position: absolute;
    z-index: 999;
    top: 80px;
    left: 32px;
    transform: translateY(16px);
    max-width: none;
    padding: 12px 24px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    color: rgba(#fff, 0.6);
    span {
      margin: 0 24px 0 4px;
    }
    [data-popper-arrow] {
      width: 16px; height: 16px;
      left: 32px; top: -8px;
    }
  }

  &-install {
    position: absolute;
    z-index: 999;
    right: 280px; top: 80px;
    min-width: 360px;
    padding: 16px;
    border-radius: 0 0 8px 8px;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.20);
    background-color: @tint_white;
    &-title {
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-tips {
      display: flex;
      align-items: center;
      max-width: 360px;
      img {
        width: 64px; height: 64px;
        margin-right: 12px;
        flex: 0 0 auto;
      }
      .tips {
        color: rgba(#1f2128, 0.6);
        :deep(b) {
          color: @tint_error;
          font-weight: 500;
        }
      }
    }
    &-actions {
      margin-top: 16px;
      display: flex;
      justify-content: flex-end;
      & > * {
        margin-left: 8px;
        min-width: 120px;
        &.w-button--normal {
          color: rgba(#1f2128, 0.6);
          border-color: rgba(#1f2128, 0.25);
        }
      }
    }
  }

  &-route {
    flex: 1 1 0;
    white-space: nowrap;

    & > * {
      display: inline-flex;
      vertical-align: middle;
      align-items: center;
      height: @height;
      margin-right: 44px;
      position: relative;
      @media (max-width: 1280px) {
        margin-right: 16px;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    &-item {
      color: #858585;
      cursor: pointer;
      font-size: 16px;
      padding: 0 18px;
      &:hover {
        color: var(--el-wf1);
        opacity: 1;
      }
      &.home {
        margin-left: 48px;
        @media (max-width: 1280px) {
          margin-left: 24px;
        }
      }
      &.is-active {
        color: var(--el-bc0);
        position: relative;
        &::after {
          content: "";
          position: absolute;
          width: 30px;
          background: var(--el-bc0);
          height: 4px;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          border-radius: 4px;
        }
        &:hover {
          color: var(--el-bc0);
        }
      }
    }
    &-badge {
      display: inline-block;
      height: 16px;
      margin-left: 4px;
      font-size: 14px;
      line-height: 16px;
      color: @label_light_1;
      background-color: @tint_primary_2;
      padding: 0 4px;
      border-radius: 2px;
    }
    &-num {
      display: inline-block;
      height: 16px;
      min-width: 16px;
      margin-left: 4px;
      padding: 0 4px;
      color: #fcfcfc;
      background-color: #eb4b4b;
      border-radius: 8px;
      text-align: center;
    }
    &-unread {
      position: absolute;
      right: 0;
      top: 50%;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: @tint_error;
      border: 2px solid @tint_dark_3;
      box-sizing: border-box;
      transform: translate(100%, -100%);
    }
  }

  &-game {
    min-width: 48px;
    width: auto;
    height: 48px;
    margin-right: 16px;
    @media (max-width: 1360px) {
      margin-right: 12px;
    }
    &-option {
      display: inline-flex;
      align-items: center;
      color: @label_dark_4;
      width: 100%;
      &:hover {
        color: @label_dark_3;
      }
    }
    .w-icon {
      width: 32px; height: 32px;
    }
  }

  &-subgame {
    min-width: 48px;
    width: auto;
    height: 48px;
    margin-right: 16px;
    @media (max-width: 1360px) {
      margin-right: 12px;
    }
    &-option {
      display: inline-flex;
      align-items: center;
      color: @label_light_1;
      font-weight: bold;
      width: 100%;
    }
  }

  &-prefer {
    .font14;
    height: 44px;
    color: @label_dark_1;
    background-color: var(--al-wal1);
    font-weight: 600;
    margin-right: 16px;
    transition: all 0.15s ease;
    @media (max-width: 1360px) {
      margin-right: 12px;
    }
    &-gapline {
      height: 16px;
      width: 1px;
      background: @tint_dark_2;
      margin: 0 7.5px;
    }
    &:hover {
      color: white;
    }
    &:active {
      color: @label_dark_1;
    }
  }

  &-search {
    flex: 0 0 auto;
    position: relative;
    height: 44px;
    margin-right: 16px;
    &-button {
      width: 40px !important;
      height: 40px !important;
      background-color: transparent;
      min-width: auto;
      padding: 0;
      line-height: 0;
      position: absolute;
      right: 0;
      top: 4px;
      z-index: 1;
      display: none;
    }
    &-inner {
      width: 240px;
      height: 44px;
      position: absolute;
      right: 0;
      top: 0;
      transition: width 0.3s;
      border-radius: var(--radius-4);
      overflow: hidden;

      &::after {
        content: '';
        background-color: var(--al-wal1);
        position: absolute;
        inset: 0;
        z-index: 0;
      }
      :deep(&) > * {
        z-index: 1;
      }

      :deep(.w-input__prefix) {
        padding: 0;
      }

      :deep(.c-autocomplete-input.w-input) {
        background: transparent;
      }
    }
    &-icon {
      width: 40px;
      height: 40px;
      background: url('~@/assets/images/img_search_nav.png') no-repeat center/contain;
      cursor: pointer;
    }
    @media (max-width: 1440px) {
      &-inner {
        width: 44px;

        :deep(.c-autocomplete-input.w-input) {
          padding: 0 12px;
        }
      }
      &.is-active &-inner {
        width: 240px;
        transition: width 0.5s;
      }
    }
    @media (max-width: 1360px) {
      margin-right: 12px;
    }
  }

  &-sign {
    flex: 0 0 auto;
    height: 44px;
    min-width: 128px;
    &:hover {
      background: @tint_primary_1;
    }
  }

  &-submenu {
    position: absolute;
    min-width: 196px;
    top: 80px;
  }

  @media (max-width: 1079px) {
    &-route {
      position: relative;
      z-index: -1;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
      }

      * {
        display: none;
      }
    }

    &-search.is-active ~ &-route {
      display: none;
    }
  }
}
</style>
