<template>
  <div class="c-nav" :style="{ height: navHeight }">
    <div class="c-nav-inner">
      <div class="c-nav-header">
        <NavSlide />
        <router-link class="c-nav-header-icon" :to="$_localePath({ name: 'Index' })" @click.native="handleGoIndexClick">
          <i class="w-icon icon-logo-mobile-nav is-interactive"></i>
        </router-link>
        <w-button class="c-nav-prefer" plain @click="isModalPreferShow = true">
          <w-icon name="icon_nav_prefer" :size="16" style="margin-right: 4px" />
          {{ preferCurrency }}
          <span class="c-nav-prefer-gapline"></span>
          {{ preferLanguage }}
        </w-button>
      </div>
      <slot></slot>
      <div v-show="title" class="c-nav-title">
        <w-button class="c-nav-back" icon name="icon_arrow_left" @click="handleBack" />
        <div class="font16 c-nav-name">
          <span @click="handleMessage">{{ title }}</span>
          <w-icon
            v-if="isInMobileMessage"
            name="icon_down_fill"
            :size="16"
            @click="handleMessage" />
        </div>
      </div>
    </div>

    <ModalPrefer :show="isModalPreferShow" @confirm="isModalPreferShow = false" @close="isModalPreferShow = false" />

    <div v-show="isModalMessage" class="c-nav-modal-message">
      <router-link
        :to="$_localePath({ name: 'Messages.Trade' })"
        class="modal-message-item font15r-min"
        @click.native="isModalMessage = false">{{
          $t('messages.trade_messages') }}</router-link>
      <router-link
        :to="$_localePath({ name: 'Messages.System' })"
        class="modal-message-item font15r-min"
        @click.native="isModalMessage = false">{{
        $t('messages.system_messages') }}</router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ModalPrefer from '@/views/components/ModalPrefer.vue';
import { LanguageShortLabel } from '@/enums';
import bus from '@/utils/bus';
import { isMobile } from '@/utils';
import { INSTALL_DEFAULT } from '@/enums/install';
import NavSlide from './components/Nav/NavSlide.vue';

export default {
  components: {
    ModalPrefer,
    NavSlide,
  },
  data() {
    return {
      installAPPLink: INSTALL_DEFAULT,
      title: this.$route.meta?.title || '',
      // 偏好设置
      isModalPreferShow: false,
      isModalMessage: false,
    };
  },
  computed: {
    ...mapGetters(['announcement', 'currencyCode', 'currencySymbol', 'textType']),
    editPrefer() {
      return this.$route.meta?.editPrefer || false;
    },
    preferCurrency() {
      return `${this.currencySymbol} ${this.currencyCode}`;
    },
    preferLanguage() {
      return LanguageShortLabel[this.textType];
    },
    isInStandalone() {
      return this.$store.state.isInStandalone;
    },
    navHeight() {
      let height = 40;
      if (this.title) height += 44;
      if (this.announcement) height += 48;
      return `${height}px`;
    },
    isInMobileMessage() {
      return isMobile && (this.$route.name === 'Messages.Trade' || this.$route.name === 'Messages.System');
    },
  },
  watch: {
    navHeight: {
      handler(val) {
        document.documentElement.style.setProperty('--nav-height', val);
      },
      immediate: true,
    },
  },
  created() {
    bus.$on('nav.title.set', this.handleTitleSet);
  },
  beforeDestroy() {
    bus.$off('nav.title.set', this.handleTitleSet);
  },
  methods: {
    handleGoIndexClick() {
      bus.$emit('nav.goindex');
    },
    handleTitleSet(value) {
      this.title = value;
    },
    handleBack() {
      this.isModalMessage = false;

      const { state } = window.history;
      if (!state || state === this.$router.START_STATE) {
        this.$router.replace(this.$_localePath({ name: 'Index' }));
      } else {
        this.$router.go(-1);
      }
    },
    handleMessage() {
      if (!this.isInMobileMessage) return;

      this.isModalMessage = !this.isModalMessage;
    },
  },
};
</script>

<style lang="less" scoped>
@headerHeight: 40px;
@innerHeight: 44px;

.c-nav {
  position: relative;

  &-inner {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;
  }

  &-header {
    height: @headerHeight;
    display: flex;
    align-items: center;
    background-color: var(--fu-grey2);

    &-icon {
      line-height: 0;
    }

    .icon {
      display: inline-block;
    }
  }

  &-title {
    height: @innerHeight;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 32px;
    background-color: @tint_white;
  }

  & .c-nav-back {
    position: absolute;
    left: 16px;
  }

  &-name {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: @label_light_1;
    font-weight: 600;

    svg {
      margin-left: 2px;
    }
  }

  &-prefer {
    .font12;
    position: absolute;
    right: 12px;
    height: 24px;
    color: rgba(#fff, .4);
    border-color: rgba(#fff, .2);
    padding: 0 12px;

    &-gapline {
      height: 12px;
      width: 1px;
      background: rgba(#fff, .2);
      margin: 0 4px;
    }
  }

  .c-nav-modal-message {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -84px;
    z-index: 10000;
    margin: 0 auto;
    width: 240px;
    border: 1px solid var(--bg-bg4);
    border-radius: 4px;
    background: var(--bg-bg1);
    box-shadow: var(--shadow-1);

    .modal-message-item {
      display: block;
      padding: 12px 16px;
      color: var(--el-gf2);
    }
  }
}
</style>
