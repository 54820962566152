// 存储key：存储的是用户点击的时间
export const POINT_TASK_NEW_TIME = 'POINT_TASK_NEW_TIME';

// point任务列表new的点击时间：存储点击时间和任务id
export const POINT_TASK_LIST_NEW_TIME = 'POINT_TASK_LIST_NEW_TIME';

// 用户信息更新事件
export const UserInfoUpdateEventEnums = {
  // 最近购买记录
  BUY_HISTORY_NEW: 'BUY_HISTORY_NEW',
  // 积分日常任务
  POINT_DAILY_LOGIN: 'POINT_DAILY_LOGIN',
};

/**
 * 流量联盟推广等级
 *
 * - NOVICE 0 推广新手
 * - PROMOTER 1 推广达人
 * - MASTER 2 推广大师
 */
export const AffiatePromoteRankEnums = {
  NOVICE: 0,
  PROMOTER: 1,
  MASTER: 2,
};

/**
 * 流量联盟推广任务id
 *
 * - ACTIVATE_EVENT 开通专属活动
 * - GIVEWAY_EVENT 专属Giveway活动
 * - EMAIL_EVENT 专属优惠券
 */
export const AffiatePromoteTaskIdEnums = {
  ACTIVATE_EVENT: 1001,
  GIVEWAY_EVENT: 1002,
  COUPON_EVENT: 1003,
};

export default {};
