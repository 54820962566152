import { mapGetters } from 'vuex';
import bus from '@/utils/bus';
import {
  LoginStatus, LanguageLabel, ProfileGuideEnum,
} from '@/enums';
import InputHistory from '@/utils/input-history';
import ModalPrefer from '@/views/components/ModalPrefer.vue';
import Autocomplete from './components/Autocomplete.vue';

const inputHistory = new InputHistory('ih-nav');
const HideActivityPopupRoute = ['Home', 'Referral'];

export default {
  components: { Autocomplete, ModalPrefer },

  data() {
    return {
      randomkey: 0, // 用于刷新用户头像及其hover弹窗
      LoginStatus,

      focusing: false,

      inputHistory,

      search: this.$route.query?.q || '',

      logoutLoading: false,

      // 偏好设置
      isModalPreferShow: false,

      // 客服相关
      feedbackState: false,
    };
  },

  methods: {
    handleGoIndexClick() {
      bus.$emit('nav.goindex');
    },
    handleSearchBtnClick() {
      this.$refs.searchInput.focus();
    },
    handleSearchInputFocus() {
      this.focusing = true;
    },
    handleSearchInputBlur() {
      this.focusing = false;
    },
    async handleSearch() {
      const { search } = this;
      if (!search) return;
      await this.$router.push(this.$_localePath({ name: 'Search', query: { q: this.search } }));
      this.$refs.searchInput.blur();
      bus.$emit('nav.search', search);
      this.inputHistory.add(search);
      // console.log('在城', this.inputHistory);
      // const { name, meta } = this.$route;
      // const { search } = this;
      // if (name.split('.')[0] === 'Account') {
      //   // 个人中心搜索跳转到市场首页进行搜索
      //   await this.$router.push({ name: 'Market.All' });
      // } else {
      //   // 模块一级页面 和 路由配置了 searchable 的才支持搜索
      //   const avail = ['Market', 'Inventory', 'Sell', 'Buy', 'Bargain'].indexOf(name) !== -1;
      //   if (!(meta && meta.searchable) && !avail) {
      //     // 跳转到模块一级页面后触发nav.search
      //     await this.$router.push({ name: name.split('.')[0] });
      //   }
      // }
      // this.$refs.searchInput.blur();
      // bus.$emit('nav.search', search);
      // this.inputHistory.add(search);
    },
    handleSearchClear() {
      console.log('handleSearchClear');
      this.search = '';
      this.$refs.searchInput.blur();
      bus.$emit('nav.search', this.search);
    },
    handleSearchSet(value) {
      console.log('handleSearchSet', value);
      this.search = value;
      this.$forceUpdate();
    },
    handleSearchAction({ type, indexes: [, itemindex] }) {
      if (type === 'title') {
        this.inputHistory.clear();
      } else if (type === 'item') {
        this.inputHistory.removeByIndex(itemindex);
      }
    },
    handleSignIn() {
      this.$logger('log_in_click');
      this.$guide(ProfileGuideEnum.signin).then(() => {
        this.$logger('log_in_success_view');
      }).catch(() => {});
    },
    // tab跳转埋点
    handleRouteTo(route) {
      const { to } = route;
      const event = {
        '/market': 'home_market_click',
        '/inventory': 'inventory_click',
        '/sell': 'mysale_click',
        '/buy': 'mybuy_click',
        '/faq': 'help_click',
      }[to];
      if (!event) return;
      this.handleTrack(event);
    },
    handleTrack(action) {
      this.$logger(action);
    },
    async handleLogout() {
      if (this.logoutLoading) return;
      this.$logger('log_out_click');
      this.logoutLoading = true;
      try {
        await this.$store.dispatch('postAppLogout');
      } catch (err) {
        this.$_handleError(err);
        this.logoutLoading = false;
        return;
      }
      this.logoutLoading = false;
    },
    calcBadge(key) {
      const info = this.notifications && this.notifications[key];
      if (!info) return '';
      const total = this.notifications[key][this.gameType] ?? 0;
      if (!total) return '';
      return total > 99 ? '99+' : `${total}`;
    },

    /**
     * @description: 客服提单
     * @param {*}
     */
    async handleFeedback() {
      this.$feedback({ onSuccess: this.$_handleAfterFeedback });
    },
  },

  computed: {
    ...mapGetters(['loginStatus', 'userInfo', 'assetBrief', 'notifications', 'feedbackUnread', 'submitInfoCount', 'textType', 'gameType', 'currencyCode', 'currencySymbol']),
    suggestions() {
      if (this.search) return [];
      if (!this.inputHistory.list.length) return [];
      return [{
        title: this.$t('nav.recent'),
        icon: 'icon_close',
        list: this.inputHistory.list.map((item) => ({
          title: item, icon: 'icon_close',
        })),
      }];
    },
    unreadTradeMessage() {
      return this.notifications?.unread_message?.total ?? 0;
    },
    unreadfeedbackMessage() {
      return !!this.feedbackUnread;
    },
    unreadSystemMessage() {
      return this.notifications?.unread_system_message?.total ?? 0;
    },
    unreadSocialMessage() {
      return this.notifications?.unread_social_message?.total ?? 0;
    },
    unreadImMessage() {
      if (this.userInfo?.user_type === 2) return 0;
      return this.notifications?.unread_im_message?.total ?? 0;
    },
    // 所有未读Tab消息
    unreadTabMsgTotal() {
      return this.gameOptions.reduce((pre, cur) => pre + (cur.unread ?? 0), 0);
    },
    unreadCoupon() {
      return this.notifications?.grant_coupon?.total ?? 0;
    },
    unreadTotal() {
      return this.submitInfoCount + this.feedbackUnread + this.unreadCoupon;
    },
    unreadMessage() {
      if (!this.notifications) return false;
      return this.unreadTradeMessage
        + this.unreadSystemMessage
        + this.unreadImMessage
        + this.unreadSocialMessage > 0;
    },
    routeMessagesName() {
      if (!this.unreadMessage || this.unreadTradeMessage) return 'Account.Messages.Trade';
      return this.unreadSystemMessage ? 'Account.Messages.System' : 'Account.Messages.Community';
    },
    isLogined() {
      return this.loginStatus === LoginStatus.logined;
    },
    routes() {
      return [
        { to: '/market', title: this.$t('nav.market') },
        { to: '/inventory', title: this.$t('nav.inventory') },
        { to: '/sell', title: this.$t('nav.sell'), badgeKey: 'to_deliver_order' },
        { to: '/buy', title: this.$t('nav.buy'), badgeKey: 'to_accept_offer_order' },
        {
          to: '/app',
          title: this.$t('nav.help'),
          submenu: [
            { to: this.$router.resolve({ name: 'Market.Faq' }).href, title: this.$t('nav.faq') },
            { to: '', method: this.handleFeedback, title: this.$t('nav.contact_support') },
            { to: '/static/user_agreement.html', title: this.$t('nav.terms_of_service'), target: '_blank' },
            { to: '/static/privacy_policy.html', title: this.$t('nav.privacy_policy'), target: '_blank' },
            { to: '/static/cookie_policy.html', title: this.$t('nav.cookie_policy'), target: '_blank' },
          ],
        },
      ];
    },
    menus() {
      const uid = this.userInfo?.uid || '';
      const list = [
        {
          to: { name: 'Account.Buy', query: { tab: 'wait', needWait: true } }, icon: 'icon_menu_order', title: this.$t('buy.history'), log: 'page_c2c_buyhistory_click',
        },
        {
          to: { name: 'Account.Coupon' }, icon: 'icon_menu_coupon', title: this.$t('account.coupon'), log: 'page_coupons_cilck', logData: { uid },
        },
        {
          to: { name: 'Account.AccountSet' }, icon: 'icon_menu_profile', title: this.$t('account.profile'), log: 'page_c2c_account_click',
        },
        {
          to: { name: 'Faq' }, icon: 'icon_menu_help', title: this.$t('account.help'), log: 'page_c2c_faq_click',
        },
        {
          to: { name: 'Account.Feedback' }, icon: 'icon_menu_feedback', title: this.$t('support.ticket_records'), tip: this.unreadfeedbackMessage, log: 'page_c2c_feedback_click',
        },
        { to: { name: 'Account.AffiliateProgram' }, icon: 'icon_dollar', title: this.$t('account.affiliate_program') },
      ];
      if (this.userInfo?.user_type === 2) {
        list.splice(2, 0, {
          to: { name: 'Seller' }, title: this.$t('lootBar.seller_center'), log: 'page_c2c_sellercenter_click', logData: { uid },
        });
      }
      return list;
    },
    preferCurrency() {
      return `${this.currencySymbol} ${this.currencyCode}`;
    },
    preferLanguage() {
      return LanguageLabel[this.textType];
    },
    baseCurrencySymbol() {
      return this.$store.getters.baseCurrency.symbol;
    },
    isGallery() {
      return this.$route.name === 'Community';
    },
    gameOptions() {
      const options = [
        { key: 'knivesout', label: 'Knivesout' },
        { key: 'csgo', label: 'CSGO' },
        { key: 'badlanders', label: 'Badlanders' },
      ];
      if (process.env.VUE_APP_DEPLOY_ENV !== 'prod') {
        options.splice(1, 0, { key: 'lol', label: 'LOL' });
      }
      return options;
    },
  },
  filters: {
    formatDuration(item) {
      const start = new Date(item.start_time * 1000);
      const end = new Date(item.end_time * 1000);
      const sm = start.getMonth() < 9 ? `0${start.getMonth() + 1}` : start.getMonth() + 1;
      const em = end.getMonth() < 9 ? `0${end.getMonth() + 1}` : end.getMonth() + 1;
      const sd = start.getDate() < 10 ? `0${start.getDate()}` : start.getDate();
      const ed = end.getDate() < 10 ? `0${end.getDate()}` : end.getDate();
      return `${sm}.${sd}-${em}.${ed}`;
    },
    formatCount(num) {
      return num > 99 ? '99+' : `${num}`;
    },
  },
  watch: {
    $route(to, from) {
      if (to.name !== from.name
          && HideActivityPopupRoute.includes(from.name)
          && this.delayShowActivityPopup) {
        this.delayShowActivityPopup = false;
        this.showActivityPopup = true;
      }
      this.isModalPreferShow = false;
    },
  },
  async created() {
    bus.$on('nav.search.set', this.handleSearchSet);
    const { feedbackToken } = this.$store.state.Feedback;
    if (!feedbackToken) {
      await this.$store.dispatch('fetchFeedbackToken');
    }
  },
  beforeDestroy() {
    bus.$off('nav.search.set', this.handleSearchSet);
  },
};
