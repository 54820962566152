import Axios from '@/utils/axios.ext';
import api from '@/api';
import SessionStorage from '@/utils/session-storage';

const state = {
  submitInfoCount: 0,
};

const getters = {
  submitInfoCount: (_state) => _state.submitInfoCount,
};
const mutations = {
  SET_SUBMIT_INFO_COUNT(_state, submitInfoCount) {
    _state.submitInfoCount = submitInfoCount;
  },
};
const actions = {
  /**
   * 获取购买历史
   * @param {string} game 游戏
   * @param {number} page_num 分页参数
   * @param {number} page_size 分页参数
   */
  getMarketBuyHistory({ rootState }, {
    pageNum, pageSize, filter, search, payId, game, queryState,
  } = {}) {
    return Axios({
      method: 'get',
      url: api.getMarketBuyHistory(),
      params: {
        game: rootState.game || game,
        page_num: pageNum,
        page_size: pageSize,
        search,
        ...filter,
        query_state: queryState,
        pay_id: payId,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 珊瑚取消订单
   * @param {string} game 游戏
   */
  postMarketBuyOrderDelete(_, { id, game } = {}) {
    return Axios({
      method: 'post',
      url: api.postMarketBuyOrderDelete(),
      data: {
        game,
        bill_order_id: id,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 获取新购买历史
   * @param {string} game 游戏
   * @param {number} page_num 分页参数
   * @param {number} page_size 分页参数
   */
  getMarketBuyHistoryNew(_, {
    pageNum, pageSize, queryState, signal, other,
  } = {}) {
    const data = {};
    if (queryState) {
      data.query_state = queryState;
    }
    return Axios({
      method: 'get',
      url: api.getMarketBuyHistoryNew(),
      params: {
        page_num: pageNum,
        page_size: pageSize,
        ...data,
        ...other,
      },
      signal,
    }).then((resp) => resp.data);
  },
  /**
   * 获取购买详情
   * @param {string} game 游戏
   */
  getMarketBuyDetailNew({ rootState }, {
    id, game, isErr = true, forPay = false,
  } = {}) {
    const data = {};
    if (!isErr) {
      data.filter_not_support_verify_err = isErr;
    }
    if (forPay) {
      data.for_pay = 1;
    }
    return Axios({
      method: 'get',
      url: api.getMarketBuyDetailNew(),
      params: {
        game: rootState.game || game,
        bill_order_id: id,
        ...data,
      },
      // 加载失败了，尝试重新加载3次
      retryTimes: 3,
    }).then((resp) => resp.data);
  },
  /**
   * 获取购买详情
   * @param {string} game 游戏
   */
  getMarketBuyDetailProgress({ rootState }, { id, game, forPay = false } = {}) {
    const params = {
      game: rootState.game || game,
      bill_order_id: id,
    };
    if (forPay) {
      params.for_pay = 1;
    }
    return Axios({
      method: 'get',
      url: api.getMarketBuyDetailProgress(),
      params,
      // 加载失败了，尝试重新加载3次
      retryTimes: 3,
    }).then((resp) => resp.data);
  },
  /**
   * 获取购买详情
   * @param {string} game 游戏
   */
  getMarketBuyDetail({ rootState }, { id, game, isErr = true } = {}) {
    const data = {};
    if (!isErr) {
      data.filter_not_support_verify_err = isErr;
    }
    return Axios({
      method: 'get',
      url: api.getMarketBuyDetail(),
      params: {
        game: rootState.game || game,
        bill_order_id: id,
        ...data,
      },
      // 加载失败了，尝试重新加载3次
      retryTimes: 3,
    }).then((resp) => resp.data);
  },
  /**
   * 提交购买表单
   * @param {string} game 游戏
   */
  postMarketBuySubmit(_, { id, game, extra = {} } = {}) {
    return Axios({
      method: 'post',
      url: api.postMarketBuySubmit(),
      data: {
        game,
        bill_order_id: id,
        extra,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 取消购买订单
   * @param {string} game 游戏
   */
  getMarketBuyOrderCancel({ rootState }, { billOders, game } = {}) {
    return Axios({
      method: 'post',
      url: api.getMarketBuyOrderCancel(),
      data: {
        game: rootState.game || game,
        bill_orders: billOders,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 提交购买表单
   * @param {string} game 游戏
   */
  getMarketBuySubmit({ rootState }, { game, id, data } = {}) {
    return Axios({
      method: 'post',
      url: api.getMarketBuySubmit(),
      data: {
        game: rootState.game || game,
        bill_order_id: id,
        ...data,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 获取市场货品列表
   * @param {string} game 游戏
   * @param {number} page_num 分页参数
   * @param {number} page_size 分页参数
   * @param {string} sort_by 排序参数
   */
  // getMarketBuyOrder({ rootState }, {
  //   pageNum, pageSize, id, filter, search,
  // } = {}) {
  //   return Axios({
  //     method: 'get',
  //     url: api.getMarketBuyOrder(),
  //     params: {
  //       game: rootState.game,
  //       page_num: pageNum,
  //       page_size: pageSize,
  //       buy_order_id: id,
  //       search,
  //       ...filter,
  //     },
  //   }).then((resp) => {
  //     if (resp.data.items.length > 0) {
  //       resp.data.items.forEach((item) => {
  //         item.subList = {};
  //       });
  //     }
  //     return resp.data;
  //   });
  // },

  /**
   * 取消求购
   * @param {number} id 商品id
   */
  // cancelMarketOrder({ rootState }, { id } = {}) {
  //   return Axios({
  //     method: 'post',
  //     url: api.cancelMarketOrder(),
  //     data: {
  //       game: rootState.game,
  //       buy_orders: [id],
  //     },
  //   }).then((resp) => resp);
  // },

  /**
   * 获取单个求购的供应记录
   * @param {number} id 商品id
   */
  // getMarketBillOrder({ rootState }, { id } = {}) {
  //   return Axios({
  //     method: 'get',
  //     url: api.getMarketBillOrder(),
  //     params: {
  //       game: rootState.game,
  //       buy_order_id: id,
  //     },
  //   }).then((resp) => resp.data);
  // },

  /**
   * 获取特定求购记录
   * @param {string} game 游戏类型
   * @param {string} goods_id 商品id
   */
  // getMarketOrderSpecificList({ rootState }, { id } = {}) {
  //   return Axios({
  //     method: 'get',
  //     url: api.getMarketOrderSpecificList(),
  //     params: {
  //       game: rootState.game,
  //       goods_id: id,
  //     },
  //   }).then((resp) => resp.data);
  // },
  /**
   * 获取特定求购价格预览
   * @param {string} game 游戏类型
   * @param {string} goods_id 商品id
   * @param {array} specific 特定求购条件
   */
  // getMarketOrderSpecificPrice({ rootState }, { id, specific } = {}) {
  //   return Axios({
  //     method: 'post',
  //     url: api.getMarketOrderSpecificPrice(),
  //     data: {
  //       game: rootState.game,
  //       goods_id: id,
  //       specific,
  //     },
  //   }).then((resp) => resp.data);
  // },

  /**
   * 求购前检查
   * @param {string} sell_order_id 订单id
   * @param {string} price 求购金额
   */
  // getMarketBuyOrderPreview({ rootState }, {
  //   id, price, num, specific,
  // } = {}) {
  //   return Axios({
  //     method: 'post',
  //     url: api.getMarketBuyOrderPreview(),
  //     data: {
  //       game: rootState.game,
  //       goods_id: id,
  //       price,
  //       num,
  //       specific,
  //     },
  //   }).then((resp) => resp.data);
  // },

  /**
   * 发布求购
   * @param {string} game 游戏类型
   * @param {string} goods_id 商品id
   * @param {string} num 求购数量
   * @param {string} price 求购价格
   * @param {string} allow_tradable_cooldown 是否接受无法立即取回饰品
   * @param {array} specific 特定求购条件
   */
  // createMarketOrder({ rootState }, {
  //   id, num, price, specific, payMethod,
  // } = {}) {
  //   return Axios({
  //     method: 'post',
  //     url: api.createMarketOrder(),
  //     data: {
  //       game: rootState.game,
  //       goods_id: id,
  //       pay_method: payMethod,
  //       num,
  //       price,
  //       specific,
  //     },
  //   }).then((resp) => resp.data);
  // },

  /**
   * 获取供应求购
   * @param {string} game 游戏类型
   * @param {string} goods_id 商品id
   * @param {array} specific 特定求购条件
   */
  // getMarketSupplyList({ rootState }, { id, forceUpdate } = {}) {
  //   const url = api.getPartnerSupplyList();
  //   return Axios({
  //     method: 'get',
  //     url,
  //     params: {
  //       game: rootState.game,
  //       buy_order_id: id,
  //       force_update: forceUpdate,
  //     },
  //   }).then((resp) => resp.data);
  // },

  /**
   * 供应求购
   * @param {string} game 游戏类型
   * @param {string} buy_order_id 求购订单ID
   * @param {float} price 价格
   * @param {array} assets 饰品列表
   */
  // createMarketSupply({ rootState }, { id, price, assets } = {}) {
  //   const url = api.createPartnerSupply();
  //   return Axios({
  //     method: 'post',
  //     url,
  //     data: {
  //       game: rootState.game,
  //       buy_order_id: id,
  //       price,
  //       assets,
  //     },
  //   }).then((resp) => resp);
  // },

  /**
   * 购买前价格计算
   * @param {string} sell_order_id 订单id
   * @param {string} num 购买数量
   */
  getMarketGoodsBuyCalculate(_, {
    game, id, num, signal,
  } = {}) {
    return Axios({
      method: 'get',
      url: api.getMarketGoodsBuyCalculate(),
      params: {
        game,
        sell_order_id: id,
        num,
        ...(SessionStorage.get('UTM_PARAMS') || {}),
      },
      signal,
    }).then((resp) => resp.data);
  },
  /**
   * 购物车购买前价格计算
   * @param {string} sell_order_id 订单id
   * @param {string} num 购买数量
   */
  getMarketGoodsBuyCartCalculate(_, {
    game, sellOrders, signal,
  } = {}) {
    return Axios({
      method: 'post',
      url: api.getMarketGoodsBuyCartCalculate(),
      data: {
        game,
        sell_orders: sellOrders,
        ...(SessionStorage.get('UTM_PARAMS') || {}),
      },
      signal,
    }).then((resp) => resp.data);
  },
  /**
   * 购买前检查
   * @param {string} sell_order_id 订单id
   * @param {string} price 购买金额
   */
  getMarketBuyPreview({ rootState }, { id, price, assetNum } = {}) {
    return Axios({
      method: 'get',
      url: api.getMarketBuyPreview(),
      params: {
        game: rootState.game,
        sell_order_id: id,
        price,
        asset_num: assetNum,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 购买前检查 （新版c2c）
   * @param {string} sell_order_id 订单id
   * @param {string} price 购买金额
   */
  postMarketBuyPreview(_, {
    id, price, game, num, assetNum, extra = {}, checkExtra, signal, ...other
  } = {}) {
    const config = {
      method: 'post',
      url: api.postMarketBuyPreview(),
      data: {
        game,
        sell_order_id: id,
        price,
        num,
        asset_num: assetNum,
        check_extra: checkExtra,
        extra,
        ...(SessionStorage.get('UTM_PARAMS') || {}),
        ...other,
      },
    };
    if (config) {
      config.signal = signal;
    }
    return Axios(config).then((resp) => resp.data);
  },

  /**
   *  购买
   * @param {string} sell_order_id 订单id
   * @param {string} price 购买金额
   * @param {string} pay_method 支付方式
   * @param {string} pay_type 支付类型
   */
  postMarketBuy({ rootState }, {
    id, price, payMethod, ...other
  } = {}) {
    return Axios({
      method: 'post',
      url: api.postMarketBuy(),
      data: {
        game: rootState.game,
        sell_order_id: id,
        price: price ? price.toString() : '',
        pay_method: payMethod,
        time_offset: new Date().getTimezoneOffset() / -60, // 下单时区
        // pay_type: payType,
        ...other,
        ...(SessionStorage.get('UTM_PARAMS') || {}),
      },
    }).then((resp) => resp.data);
  },

  /**
   *  购物车结算
   * @param {Array} sell_orders 购物车信息
   * @param {string} pay_method 支付方式
   * @param {string} pay_type 支付类型
   */
  postMarketBuyCart({ rootState }, {
    sellOrders, price, payMethod, ...other
  } = {}) {
    return Axios({
      method: 'post',
      url: api.postMarketBuyCart(),
      data: {
        game: rootState.game,
        sell_orders: sellOrders,
        price: price ? price.toString() : '',
        pay_method: payMethod,
        time_offset: new Date().getTimezoneOffset() / -60, // 下单时区
        // pay_type: payType,
        ...other,
        ...(SessionStorage.get('UTM_PARAMS') || {}),
      },
    }).then((resp) => resp.data);
  },

  /**
   * 购买前检查 （新版c2c）
   * @param {string} sell_orders 订单
   * @param {string} price 购买金额
   */
  postMarketBuyCartPreview(_, {
    game, sellOrders, extra = {}, checkExtra, signal, ...other
  } = {}) {
    const config = {
      method: 'post',
      url: api.postMarketBuyCartPreview(),
      data: {
        game,
        sell_orders: sellOrders,
        check_extra: checkExtra,
        extra,
        ...(SessionStorage.get('UTM_PARAMS') || {}),
        ...other,
      },
    };
    if (config) {
      config.signal = signal;
    }
    return Axios(config).then((resp) => resp.data);
  },

  postMarketCashierPreview(_, {
    game, id, price, num, assetNum, exchangeKey, signal, ...other
  } = {}) {
    const config = {
      method: 'post',
      url: api.postMarketCashierPreview(),
      data: {
        game,
        sell_order_id: id,
        price,
        num,
        asset_num: assetNum,
        check_extra: false,
        exchange_key: exchangeKey,
        ...(SessionStorage.get('UTM_PARAMS') || {}),
        ...other,
      },
    };
    if (config) {
      config.signal = signal;
    }
    return Axios(config).then((resp) => resp.data);
  },

  postMarketCashierCartPreview(_, {
    game, sellOrders, price, num, assetNum, exchangeKey, signal, ...other
  } = {}) {
    const config = {
      method: 'post',
      url: api.postMarketCashierCartPreview(),
      data: {
        game,
        sell_orders: sellOrders,
        price,
        num,
        asset_num: assetNum,
        check_extra: false,
        exchange_key: exchangeKey,
        ...(SessionStorage.get('UTM_PARAMS') || {}),
        ...other,
      },
    };
    if (config) {
      config.signal = signal;
    }
    return Axios(config).then((resp) => resp.data);
  },
};

const module = {
  state, getters, mutations, actions,
};

export default module;
