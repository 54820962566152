export const GameType = {
  knivesout: 'knivesout',
  lol: 'lol',
};
export const PlatformType = {
  [GameType.knivesout]: {
    en: 9,
    ja: 8,
    zh_Hant: 10,
  },
};
export const C2cPlatformType = {
  en: 21,
  ja: 22,
  zh_Hant: 23,
  id: 24,
};
export const GameAppId = {
  knivesout: 1,
};
export const CommentType = {
  preview: 1,
};
export const ContainerType = {
  itemset: 'itemset',
  weaponcase: 'weaponcase',
};
export const InspectTagType = {
  '3dview': 0,
  screenshot: 1,
};

export const DepositMethods = {
  checkout: 'CHECKOUT_CARD',
  llpayCard: 'LIANLIANPAY_CARD',
  llpayKoibini: 'LIANLIANPAY_KONBINI',
  llpayPayeasy: 'LIANLIANPAY_PAYEASY',
  paymentwall: 'PAYMENTWALL',
  balance: 'BALANCE', // 钱包支付
  globalAlipay: 'GLOBAL_ALIPAY',
  adyenCard: 'ADYEN_CARD',
  adyenPaysafecard: 'ADYEN_PAYSAFECARD',
  adyenMomo: 'ADYEN_MOMO',
  paypal: 'PAYPAL',
  applePay: 'APPLE_PAY',
  pingpongCard: 'PINGPONG_CARD',
  pingpongQiwi: 'PINGPONG_QIWI',
  dukpayCard: 'DUKPAY_BANK_CARD',
  dukpayTinkoff: 'DUKPAY_TINKOFF_PAY',
  tripleA: 'TRIPLE_A',
};
export const DepositPayType = {
  balance: 1,
  checkout: 2,
  llpayCard: 5,
  llpayKoibini: 6,
  llpayPayeasy: 9,
  paymentwall: 8,
  globalAlipay: 11,
  paypal: 12,
  applePay: 13,
  pingpongCard: 14,
  pingpongQiwi: 15,
  dukpayCard: 16,
  dukpayTinkoff: 17,
  tripleA: 19,
};
// apply pay config
export const APPLEPAYCONFIG = {
  countryCode: 'HK', // 卖家主体的国家代码，默认是HK
  merchantCapabilities: ['supports3DS'],
  supportedNetworks: ['visa', 'masterCard'],
  label: 'LootBar', // 固定主体文案，不放weblate
  amount: 0.01, // 金额，默认是0.01元
};
export const PaymentwallCategory = {
  CreditCards: '1',
  BankTransfer: '2',
  PrepaidCards: '3',
  EWallet: '4',
  MobilePayment: '5',
};

// Bill Order 有调整时需要同步修改 BillOrderPayProgressMap
export const BillOrderProgress = {
  INIT: 100, // 初始化
  TO_PAY: 101, // 等待买家支付
  PAYING: 102, // 买家支付中
  PAY_SUCCESS: 103, // 买家支付成功
  PAY_FAIL: 104, // 买家支付失败
  REFUNDING: 105, // 买家退款中
  REFUND_SUCCESS: 106, // 买家退款成功
  REFUND_FAIL: 107, // 买家退款失败
  DIVIDING: 108, // 结算分账中
  DIVIDE_SUCCESS: 109, // 结算分账成功
  DIVIDE_FAIL: 110, // 结算分账失败
  TO_DELIVER: 201, // 等待卖家发货
  DELIVERING: 202, // 卖家发货至Bot中
  DELIVER_SUCCESS: 203, // 卖家发货至Bot成功
  DELIVER_FAIL: 204, // 卖家发货至Bot失败
};
export const PayOrderProgress = {
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILED: 'failed',
  PROCESSING: 'processing',
  REFUNDED: 'refunded',
  CANCEL: 'cancel',
};

export const BillOrderState = {
  CREATED: 1, // 交易中
  SUCCESS: 2, // 交易成功
  FAIL: 3, // 交易失败
  CLOSING: 4, // 结算中
  PART_SUCCESS: 5, // 部分成功
  CANCEL: 6, // 取消
};

export const BillOrderStateMap = {
  [BillOrderState.CREATED]: PayOrderProgress.PROCESSING,
  [BillOrderState.SUCCESS]: PayOrderProgress.SUCCESS,
  [BillOrderState.FAIL]: PayOrderProgress.FAILED,
  [BillOrderState.CLOSING]: PayOrderProgress.PROCESSING,
  [BillOrderState.PART_SUCCESS]: PayOrderProgress.FAILED,
  [BillOrderState.CANCEL]: PayOrderProgress.CANCEL,
};

export const BillOrderPayProgressMap = {
  [BillOrderProgress.INIT]: PayOrderProgress.PROCESSING,
  [BillOrderProgress.TO_PAY]: PayOrderProgress.PROCESSING,
  [BillOrderProgress.PAYING]: PayOrderProgress.PROCESSING,
  [BillOrderProgress.PAY_SUCCESS]: PayOrderProgress.SUCCESS,
  [BillOrderProgress.PAY_FAIL]: PayOrderProgress.FAILED,
  [BillOrderProgress.REFUNDING]: PayOrderProgress.REFUNDED,
  [BillOrderProgress.REFUND_SUCCESS]: PayOrderProgress.REFUNDED,
  [BillOrderProgress.REFUND_FAIL]: PayOrderProgress.SUCCESS,
  [BillOrderProgress.DIVIDING]: PayOrderProgress.SUCCESS,
  [BillOrderProgress.DIVIDE_SUCCESS]: PayOrderProgress.SUCCESS,
  [BillOrderProgress.DIVIDE_FAIL]: PayOrderProgress.SUCCESS,
  [BillOrderProgress.TO_DELIVER]: PayOrderProgress.SUCCESS,
  [BillOrderProgress.DELIVERING]: PayOrderProgress.SUCCESS,
  [BillOrderProgress.DELIVER_SUCCESS]: PayOrderProgress.SUCCESS,
  [BillOrderProgress.DELIVER_FAIL]: PayOrderProgress.SUCCESS,
};

// 前端使用的goods_type变量
export const GOODSTYPE = {
  ASSET: '0', // 道具交易
  TOPUP: '1', // 充值：直充
  CDKEY: '2', // CDKEY
  ACCOUNT: '3', // 初始号
  TOPUPLOGIN: '4', // 充值：代充
  COINTRADE: '5', // 金币交易
  ITEMTRADE: '6', // 标品道具
  SBC: '7', // 游戏SBC
};

// goods_type和服务端别名映射关系
export const GOODSTYPEMAPNAME = {
  0: 'trade', // 道具交易
  1: 'recharge', // 充值: 直充
  2: 'cdkey', //  CDKEY,
  3: 'new_account', // 初始号
  5: 'coin_trade', // 金币交易
  6: 'item_trade', // 道具交易
  7: 'sbc', // 游戏SBC
};

// goods_type和前端路由映射关系
export const GOODSTYPE2ROUTERMAP = {
  2: 'cd-key', // cdkey
  3: 'new-account', // 初始号
  4: 'login-top-up', // 代充
  5: 'coin-trade', // 金币交易
};

// rulepage页面路径名取对应的值
export const RULEROUTEKEY = {
  'explanation': process.env.VUE_APP_DEPLOY_ENV === 'prod' ? 'wagon_1000_logintopup' : 'wagon_1000_daichongshuoming',
};

// goods_type数字对应的交易类型
export const GOODSTYPENUM = {
  TRADE: 0, // 道具交易
  RECHARGE: 1, // 充值: 直充
  CDKEY: 2, //  CDKEY,
  NEWACCOUNT: 3, // 初始号
  COINTRADE: 5, // 金币交易
  ITEMTRADE: 6, // 标品道具
  SBC: 7, // 游戏SBC
};

export const COLLECTIONMODE = {
  BEFORE: 1,
  AFTER: 2,
};

export const SELLORDERFILTERKEY = {
  SEARCH: 'search_keyword',
  SORT: 'sort_by',
  SUBGAMENAME: 'subgame_name',
};

export const TABNAME = {
  ASSET: 'trade', // 道具交易
  TOPUP: 'recharge', // 充值
  CDKEY: 'cdkey', // CDKEY
  ACCOUNT: 'new_account', // 初始号
  COINTRADE: 'coin_trade', // 初始号
  ITEMTRADE: 'item_trade', // 标品道具
  SBC: 'sbc', // 游戏SBC
};

export const PayMethodEnum = {
  airwallex: 3,
  payoneer: 4,
  payoneerCard: 21,
  amazon: 20,
  paypal: 22,
};

export const ROUTERPATH = {
  recharge: 'top-up', // 充值: 直充
  cdkey: 'cd-key', // CDKEY
  new_account: 'new-account', // 初始号
  coin_trade: 'game-coins', // 初始号
  item_trade: 'game-items', // 标品道具
  sbc: 'game-sbc', // 游戏SBC
};

export const SERVICETYPEROUTENAME = {
  'recharge': 'Topup',
  'cdkey': 'Detail',
  'new_account': 'List',
  'coin_trade': 'Coin',
  'item_trade': 'Gameitem',
  'sbc': 'GameSbc',
};

export const GOODTYPE = {
  'top-up': 'recharge', // 充值: 直充
  'cd-key': 'cdkey', // CDKEY
  'new-account': 'new_account', // 初始号
  'game-coins': 'coin_trade', // 初始号
  'game-items': 'item_trade', // 标品道具
  'game-sbc': 'sbc', // 游戏SBC
};

export const OnlineStatus = {
  Offline: 0,
  Online: 1,
  Appoint: 2, // 预约
  PreOrder: 3, // 即将上架(可购买优惠券商品)
};

export const SkuType = {
  Goods: 5,
  Coupon: 6,
  Bundle: 7,
  Custom: 9, // 自定义商品
  CustomCoin: 9,
};

export const HTMLFNTYPE = {
  'submitinfo': 'submitInfo', // 提交信息
  'confirmreceipt': 'confirmReceipt', // 确认收货
  'ps4/5': 'ps4/5', // 查看ps4/5
  'get18nkey': 'get18nkey', // 获取18nkey
};
