<template>
  <div class="c-nav__slide">
    <w-button
      class="c-nav__slide-header-menu"
      icon
      :icon-size="20"
      name="icon_sidebar"
      size="m"
      @click="handleOpenRoute" />
    <w-modal :show="isModalRouteShow" @close="handleCloseRoute">
      <div
        class="c-nav__slide-popover c-nav__slide-route-popover font16"
        :class="{ 'is-active': slideinRouteActive }">
        <w-scrollbar style="height: 100%; z-index: 1">
          <div class="c-nav__slide-popover-page sidebar">
            <div class="c-nav__slide-popover-inner">
              <router-link
                style="position: relative"
                :to="$_localePath({ name: 'Index' })"
                @click.native="handleCloseRoute">
                <span>{{ $t("lootBar.appbar_home") }}</span>
                <w-icon class="icon icon-arrow" name="icon_arrow_right" :size="16" />
              </router-link>
              <a href="javascript:void(0);" class="c-nav__slide-route-games">
                <div
                  class="c-nav__slide-games-label"
                  :class="{ 'is-active': isRouteGamesActive }"
                  @click="handleGamesSwitch">
                  <span>{{ $t('lootBar.nav_games') }}</span>
                  <w-icon class="icon icon-arrow" name="icon_arrow_right" :size="16" />
                </div>
                <div class="c-nav__slide-games" :class="{ 'is-active': isRouteGamesActive }">
                  <div class="c-nav__slide-games-item">
                    <div
                      class="c-nav__slide-games-title font14r">
                      {{ $t('lootBar.nav_popular_games') }}
                    </div>
                    <ul v-if="hotAppList && hotAppList.length">
                      <li v-for="(item, index) of hotAppList" :key="index">
                        <router-link :to="handleResolveGameUrl(item)"
                          @click.native="handleGameLogger(item, 'c2c_popular_game_click')">
                          <img :src="item?.game_extra_info?.list_icon_url
                            +'?fop=imageView/2/w/48/h/48'" />
                          <span class="font15r-min">{{ item.desc }}</span>
                          <i v-if="item.name === 'fc25'"
                            class="w-icon icon-tag-new c-nav__slide-games-tag"></i>
                        </router-link>
                      </li>
                    </ul>
                  </div>
                  <div class="c-nav__slide-games-item">
                    <div
                      class="c-nav__slide-games-title font14r">
                      {{ $t('lootBar.nav_all_games') }}
                    </div>
                    <ul v-if="allAppList && allAppList.length">
                      <li v-for="(item, index) of allAppList" :key="index">
                        <router-link :to="handleResolveGameUrl(item)"
                          @click.native="handleGameLogger(item, 'c2c_allgame_click')">
                          <img :src="item?.game_extra_info?.list_icon_url
                            +'?fop=imageView/2/w/48/h/48'" />
                          <span class="font15r-min">{{ item.desc }}</span>
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </a>
              <a :href="blogUrl">
                <span>{{ $t("global.blog") }}</span>
                <w-icon class="icon icon-arrow" name="icon_arrow_right" :size="16" />
              </a>
              <div v-if="!isInStandalone" class="c-nav__slide-popover-getapp">
                <div>{{ $t("global.get_app") }}</div>
                <a
                  rel="nofollow"
                  :href="installAPPLink"
                  @click="$logger('page_market_mobile_downloadapp_click')">
                  <img src="@/assets/images/btn_down_lootbar.png" alt="" />
                </a>
              </div>
            </div>
          </div>
        </w-scrollbar>
      </div>
    </w-modal>
  </div>
</template>

<script>
import { TextType, BlogUrlEnumLanguage, BlogUrlEnum } from '@/enums';
import { INSTALL_DEFAULT } from '@/enums/install';
import bus from '@/utils/bus';

import mixin from './Games.mixin';

export default {
  mixins: [mixin],
  data() {
    return {
      installAPPLink: INSTALL_DEFAULT,
      isModalRouteShow: false,
      slideinRouteActive: false,
      isRouteGamesActive: false,
    };
  },
  computed: {
    blogUrl() {
      const blogUrl = BlogUrlEnumLanguage[this.textType] || BlogUrlEnumLanguage[TextType.en];
      const urlParamsData = {
        utm_source: this.$route.query.utm_source || '',
        utm_medium: this.$route.query.utm_medium || '',
        utm_campaign: this.$route.query.utm_campaign || '',
      };
      const url = new URL(blogUrl, BlogUrlEnum);
      const urlParams = new URLSearchParams(url.search);
      Object.keys(urlParamsData).forEach((key) => {
        if (urlParamsData[key]) {
          urlParams.set(key, urlParamsData[key]);
        }
      });
      url.search = urlParams.toString();
      return url.href;
    },
    isInStandalone() {
      return this.$store.state.isInStandalone;
    },
  },
  methods: {
    handleOpenRoute() {
      this.isModalRouteShow = true;
      window.setTimeout(() => {
        this.slideinRouteActive = true;
      }, 16);
    },
    handleCloseRoute() {
      this.slideinRouteActive = false;
      window.setTimeout(() => {
        this.isModalRouteShow = false;

        window.setTimeout(() => {
          bus.$emit('nav.goindex');
        }, 800);
      }, 16);
    },
    handleGameLogger(item, logger) {
      this.$logger(logger, { game: item.desc });
      this.handleCloseRoute();
    },
    handleGamesSwitch() {
      this.isRouteGamesActive = !this.isRouteGamesActive;

      if (this.isRouteGamesActive) {
        this.$logger('c2c_tabbar_view');
      }
    },
  },
};
</script>

<style lang="less" scoped>
.c-nav__slide-header-menu {
  padding: 12px;
  border-radius: var(--radius-4);

  &,
  &.is-icon:not(.is-flat, .is-plain) {
    background: var(--al-wal1) !important;
  }

  &.is-icon:not(.is-flat, .is-plain).w-button--m {
    width: auto;
    height: auto;
    color: var(--nc-white);
  }
}

.c-nav__slide-popover {
  position: fixed;
  width: 320px;
  height: 100%;
  background-color: @tint_white;
  transition: all 0.2s;
  overflow: hidden;

  &.is-active {
    transform: translate(0, 0);
  }

  .c-nav__slide-popover-inner {
    padding-bottom: 47px;
    border-bottom: 4px solid @tint_dark_2;

    &:last-child {
      border-bottom: 0;
    }

    & > * {
      display: flex;
      align-items: center;
      height: 56px;
      padding: 0 16px;
      color: @label_light_2;

      &.c-nav__slide-route-games {
        flex-direction: column;
        margin-left: 0;
        margin-right: 0;
        padding: 0;
        height: auto;

        &,
        &:hover {
          opacity: 1;
          background: var(--bg-bg1);
        }

        .c-nav__slide-games-label {
          flex: 1;
          display: flex;
          align-items: center;
          padding: 16px;
          width: 100%;

          &:hover {
            background: #f0f0f0;
          }

          &.is-active {
            .icon-arrow {
              transform: rotate(90deg);
            }
          }

          .icon-arrow {
            transition: all 0.15s ease;
          }
        }
      }

      span {
        flex: 1 1 0;
        display: flex;
        align-items: center;
      }
    }

    & > a {
      border-bottom: 1px solid #ebecf0;

      &:hover {
        background-color: @tint_dark_3;
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  .c-nav__slide-games {
    width: 100%;
    height: 0;
    transform: scaleY(0);
    transform-origin: top;
    overflow-y: hidden;
    background: #f2f2f2;
    transition: all 0.15s ease;

    &.is-active {
      height: auto;
      transform: scaleY(1);
    }

    .c-nav__slide-games-item {
      border-bottom: 2px solid var(--nc-g9);

      &:last-child {
        border-bottom: 0 none;
      }
    }

    .c-nav__slide-games-title {
      padding: 10px 16px 0;
      color: var(--el-gf2);
    }

    .c-nav__slide-games-tag {
      margin-left: 8px;
    }

    ul {
      li {
        a {
          display: flex;
          align-items: center;
          padding: 12px 16px;

          &:hover {
            background: var(--nc-g9);
            opacity: 1;
          }

          img {
            align-self: flex-start;
            margin-right: 8px;
            width: 24px;
            height: 24px;
            border-radius: var(--radius-4);
          }

          span {
            flex: 0 0 auto;
            max-width: 220px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            text-overflow: ellipsis;
            color: var(--el-gf1);
          }
        }
      }
    }
  }

  .c-nav__slide-popover-getapp {
    display: block;
    line-height: 24px;
    font-size: 16px;
    margin-top: 12px;

    img {
      margin-top: 10px;
      width: 166px;
    }
  }
}

.c-nav__slide-route-popover {
  left: 0;
  top: 0;
  transform: translate(-320px, 0);
}
</style>
