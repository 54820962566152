import ua from './ua';

export function isString(o) {
  return Object.prototype.toString.call(o) === '[object String]';
}
export function isNumber(o) {
  return Object.prototype.toString.call(o) === '[object Number]';
}
export function isObject(o) {
  return Object.prototype.toString.call(o) === '[object Object]';
}
export function isArray(o) {
  return Object.prototype.toString.call(o) === '[object Array]';
}
export function guidS4() {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
}
export function guid() {
  const s4 = guidS4;
  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}
export function padLeft(str, len) {
  let _str = Number(str);
  const l = (`${str}`).length;
  _str = (l < len ? '0' : '') + str;
  return _str;
}

export function getUnitPrice(precision) {
  if (precision === 0) return '1';
  return `0.${padLeft('1', precision)}`;
}

export function generatePriceFormatterWithPrecision(precision) {
  return (value) => {
    let newValue = value.toString().replace(',', '.').replace(/[^\d.]/g, ''); // 去掉非数字和.的字符
    const dotIndex = newValue.indexOf('.');
    if (dotIndex !== -1) {
      newValue = newValue.replace(/\./g, '');
      if (precision) {
        newValue = `${newValue.slice(0, dotIndex)}.${newValue.slice(dotIndex, dotIndex + 2)}`;
      }
    }
    let num = newValue.split('.')[0]; // 整数部分
    const dec = newValue.split('.')[1]; // 小数部分
    let rtStr = '';
    if (num) { // 有整数部分
      num = parseInt(num, 10).toString(10);
      // 限制金额的大小位数
      num = num.substring(0, 10);
      rtStr = num + (dec !== undefined ? (`.${dec}`) : '');
    } else {
      if (dec !== undefined || newValue === '.') {
        rtStr = `0.${dec}`;
      }
    }
    return rtStr;
  };
}

export const formatter = {
  price: generatePriceFormatterWithPrecision(2),
  integer: (value) => {
    if (!/^\d*$/.test(value)) return '';
    const _value = Number(value);
    return _value > 0 ? _value : '';
  },
  authcode: (value, oldValue) => {
    if (value.length > 6) return oldValue;
    return value.replaceAll(/[^a-zA-Z0-9]/g, '');
  },
};
export function isObjectEqual(a, b) {
  if (a === undefined) a = {};
  if (b === undefined) b = {};

  // handle null value #1566
  if (!a || !b) { return a === b; }
  const aKeys = Object.keys(a).sort();
  const bKeys = Object.keys(b).sort();
  if (aKeys.length !== bKeys.length) {
    return false;
  }
  return aKeys.every((key, i) => {
    const aVal = a[key];
    const bKey = bKeys[i];
    if (bKey !== key) { return false; }
    const bVal = b[key];
    // query values can be null and undefined
    if (aVal == null || bVal == null) { return aVal === bVal; }
    // check nested equality
    if (typeof aVal === 'object' && typeof bVal === 'object') {
      return isObjectEqual(aVal, bVal);
    }
    return String(aVal) === String(bVal);
  });
}

export const isMobile = () => ua.os.phone || ua.os.tablet || window.innerWidth < 750;
export const isInBuffClient = () => ua.os.client;
export const isPartner = () => ua.os.partner;
export const isWeiXin = () => navigator.userAgent.toLowerCase().match(/MicroMessenger/i) === 'micromessenger';
export const isQQbrw = () => navigator.userAgent.toLowerCase().indexOf('qqbrowser') !== -1; // QQ浏览器;
export const isQQ = () => navigator.userAgent.toLowerCase().match(/qq/i) === 'qq' && isQQbrw() && isWeiXin();
export const isWeibo = () => navigator.userAgent.toLowerCase().match(/weibo/i) === 'weibo';
export const isUC = () => navigator.userAgent.toLowerCase().match(/ucbrowser/i) === 'ucbrowser';
export const isAweme = () => navigator.userAgent.toLowerCase().match(/aweme/i) === 'aweme';
export const isNewsArticle = () => navigator.userAgent.toLowerCase().match(/newsarticle/i) === 'newsarticle';
export const isTiktok = () => !!navigator.userAgent.toLocaleLowerCase().match(/bytedancewebview/i);
export const isIOSChrome = /CriOS/i.test(navigator.userAgent);
export const isiOS = () => ua.os.ios;
export const isIphoneX = () => {
  if (!ua.os.ios) return false;
  const ratio = window.devicePixelRatio || 1;
  const winScreen = window.screen;
  const w = winScreen.width * ratio;
  const h = winScreen.height * ratio;
  const screen = {
    width: Math.min(w, h),
    height: Math.max(w, h),
  };
  const isX = screen.width === 1125 && screen.height === 2436;
  const isXSMAX = screen.width === 1242 && screen.height === 2688;
  const isXR = (screen.width === 750 && screen.height === 1624)
    || (screen.width === 828 && screen.height === 1792);
  const is12 = (screen.width === 1170 && screen.height === 2532)
    || (screen.width === 1284 && screen.height === 2778)
    || (screen.width === 1080 && screen.height === 2340);

  return isX || isXSMAX || isXR || is12;
};
export const isStandalone = () => window.navigator.standalone
  || window.matchMedia('(display-mode: standalone)').matches;
export const isGoogleBot = () => !!navigator.userAgent.toLowerCase().match(/googlebot|google-inspectiontool|lootbarbot/);
export const isFromGoogleSearch = (query) => document.referrer.includes('google')
  || query.gclid || query.gad_source;

// 删除obj中的空值
export const removeObjectEmpty = (obj = {}) => Object.entries(obj)
  .filter(([, v]) => v != null)
  .reduce((a, [k, v]) => ({ ...a, [k]: v === Object(v) ? removeObjectEmpty(v) : v }),
    {});

export const extractTime = (time) => {
  const date = new Date(time);
  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
    hour: date.getHours(),
    minutes: date.getMinutes(),
    seconds: date.getSeconds(),
  };
};

export const getStartOfTime = (time, dimension = 'second') => {
  const {
    year, month, day, hour, minutes, seconds,
  } = extractTime(time);
  let str;
  switch (dimension) {
    case 'year':
      str = `${year}/1/1 0:0:0`;
      break;
    case 'month':
      str = `${year}/${month}/1 0:0:0`;
      break;
    case 'day':
      str = `${year}/${month}/${day} 0:0:0`;
      break;
    case 'hour':
      str = `${year}/${month}/${day} ${hour}:0:0`;
      break;
    case 'minutes':
      str = `${year}/${month}/${day} ${hour}:${minutes}:0`;
      break;
    case 'second':
      str = `${year}/${month}/${day} ${hour}:${minutes}:${seconds}`;
      break;
    default:
  }
  return new Date(str).getTime();
};

export const getEndOfTime = (time, dimension = 'day') => {
  let timestamp = getStartOfTime(time, dimension);
  let offset;
  switch (dimension) {
    case 'day':
      offset = 24 * 60 * 60 * 1000; break;
    case 'hour':
      offset = 60 * 60 * 1000; break;
    case 'minutes':
      offset = 60 * 1000; break;
    case 'second':
      offset = 1000; break;
    default:
  }
  timestamp = timestamp + offset - 1;
  return timestamp;
};

export const getJumpH5URL = (url = '') => {
  if (!url.startsWith('http')) {
    url = `https://${window.location.host}/${url}`;
  }
  return `gearup-wagon://jump_h5?url=${encodeURIComponent(url)}`;
};

// 异步加载js文件, 若加载失败，则再试加载一次，默认重试3次
export const loadScriptWithRetr = (url, maxRetries = 3) => new Promise((resolve, reject) => {
  async function loadScript(retries) {
    try {
      const script = document.createElement('script');
      script.src = url;
      document.head.appendChild(script);
      script.onload = resolve;
      script.onerror = () => {
        if (retries > 0) {
          loadScript(retries - 1);
        } else {
          reject(new Error(`Failed to load ${url} after multiple attempts`));
        }
      };
    } catch (error) {
      reject(error);
    }
  }

  loadScript(maxRetries);
});

export const removeBBCode = (content) => content
  .replace(/\n/g, ' ')
  .replace(/\[link url="(\S+?)"](.+?)\[\/link\]/g, (match, $1, $2) => $2)
  .replace(/\[b\](.+?)\[\/b\]/g, (match, $1) => $1)
  .replace(/\[color=(.+?)\](.+?)\[\/color\]/g, (match, $1, $2) => $2);
