<template>
  <div
    ref="reference"
    :class="[
      'w-select',
      `w-select--${size}`,
      {
        'is-disabled': selectDisabled,
        'is-reating': selectReating,
        'w-select--selected': isSelected,
        'w-select--active': active,
      },
    ]"
    tabindex="0">
    <div
      class="w-select__wrap"
      :class="{'is-placeholder': !isSelected}"
      @click="handleToggleActive">
      <div class="w-select__inner">
        <template v-if="!isSelected">{{ placeholder }}</template>
        <template v-else>
          <template v-if="$scopedSlots.default">
            <slot :selected="selected"></slot>
          </template>
          <template v-else-if="multiple">
            {{ selected.map((item) => item.label).join(', ') }}
          </template>
          <template v-else>{{ selected.label }}</template>
        </template>
      </div>
      <w-button
        v-if="!selectDisabled && clearable && isSelected"
        class="w-select__clear"
        icon name="icon_home_clearfilter"
        :icon-size="12"
        @click.stop="handleClear"
        @mousedown.native.prevent />
      <i class="w-select__icon"></i>
    </div>
    <SelectDropdown
      ref="dropdown"
      class="w-select-dropdown"
      :class="[
        type ? `w-select-dropdown--${type}` : '',
      ]"
      :show="active"
      :title="title"
      @close="handleCloseActive">
      <w-scrollbar
        wrap-class="w-select-dropdown__wrap"
        auto-height>
        <template v-for="(option, index) in options">
          <div
            v-if="option.key !== undefined"
            :key="option.key"
            class="w-select-dropdown__item"
            :class="{'is-selected': selected && option.key === selected.key}"
            @click="handleSelect(option)">
            <slot
              v-if="$scopedSlots.option"
              name="option"
              :option="option"></slot>
            <template v-else>{{ option.label }}</template>
            <w-icon v-if="selected && option.key === selected.key" name="icon_done" :size="20" />
          </div>
          <div
            v-else-if="option.label"
            :key="option.label"
            class="w-select-dropdown__label">
            <slot v-if="$scopedSlots.label" name="label" :option="option"></slot>
            <template v-else>{{ option.label }}</template>
          </div>
          <hr v-else :key="`hr-${index}`" class="w-select-dropdown__hr" />
        </template>
      </w-scrollbar>
    </SelectDropdown>
  </div>
</template>

<script>
import SelectDropdown from './select-dropdown.vue';

export default {
  components: { SelectDropdown },
  props: {
    value: [String, Number, Array],
    title: String,
    size: {
      type: String,
      default: 'm',
    },
    clearable: Boolean,
    disabled: Boolean,
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    placeholder: String,
    type: String,
    multiple: Boolean,
  },
  data() {
    return {
      active: false,
    };
  },
  computed: {
    isSelected() {
      return this.multiple ? this.selected.length > 0 : !!this.selected;
    },
    selected: {
      get() {
        if (this.multiple) {
          const { value = [] } = this;
          return this.options.filter((option) => value.includes(option.key));
        }

        if (this.value === undefined || this.value === '' || this.value === null) return null;
        const match = this.options.find((option) => option.key === this.value);
        return match;
      },
      set(val) {
        if (this.multiple) {
          this.$emit('input', val);
        } else {
          this.$emit('input', val?.key, val);
        }
      },
    },
    selectDisabled() {
      return this.disabled;
    },
    selectReating() {
      return this.reating;
    },
  },
  watch: {
    selectDisabled(val) {
      if (val) this.active = false;
    },
    active(val) {
      if (val) {
        this.$emit('focus');
      } else {
        this.$emit('blur');
      }
    },
  },
  methods: {
    handleCloseActive() {
      this.active = false;
    },
    handleToggleActive() {
      if (this.selectDisabled) return;
      if (this.active) {
        this.handleCloseActive();
      } else {
        this.active = true;
      }
    },
    handleSelect(option) {
      if (option.disabled) return;
      if (this.multiple) {
        let selected = [...this.selected];
        if (selected.some((item) => item.key === option.key)) {
          selected = selected.filter((item) => item.key !== option.key);
        } else {
          selected.push(option);
        }
        this.selected = selected.map((item) => item.key);
      } else {
        this.selected = option;
        this.handleCloseActive();
      }
    },
    handleClear() {
      if (this.multiple) {
        this.selected = [];
      } else {
        this.selected = null;
      }
    },
  },
};
</script>
