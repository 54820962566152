import Vue from 'vue';
import VueRouter from 'vue-router';
import { i18n } from '@/utils/i18n';
import { isInBuffClient } from '@/utils';
import { GOODSTYPE2ROUTERMAP } from '@/enums';
import bus from '@/utils/bus';
import { beforeEachHook, hreflangPrefix } from './common';

Vue.use(VueRouter);

// 重复可用的components
// faq
const FAQ = () => import(/* webpackChunkName: "common" */ '../views/Account/HelpCenter/HelpCenter.vue');
// 个人账号-钱包-增值
const ACCOUNTMYBALANCEDEPOSIT = () => import(/* webpackChunkName: "accountBalance" */ '../views/Account/Balance/Deposit/Deposit.mobile.vue');
// 个人账号-钱包-提现
const ACCOUNTMYBALANCEWITHDRAW = () => import(/* webpackChunkName: "accountBalance" */ '../views/Account/Balance/Withdraw/Withdraw.mobile.vue');
// 个人账号-钱包-历史
const ACCOUNTMYBALANCEHISTORY = () => import(/* webpackChunkName: "accountBalance" */ '../views/Account/Balance/History/History.mobile.vue');
// 个人账号-积分-任务
const ACCOUNTPOINTTASK = () => import(/* webpackChunkName: "accountPoint" */ '../views/Account/Point/Task/Task.mobile.vue');
// 个人账号-积分-兑换
const ACCOUNTPOINTREDEEM = () => import(/* webpackChunkName: "accountPoint" */ '../views/Account/Point/Redeem/Redeem.mobile.vue');
// 个人账号-积分-明细
const ACCOUNTPOINTLOG = () => import(/* webpackChunkName: "accountPoint" */ '../views/Account/Point/Log/Log.mobile.vue');
// 个人账号-设置
const ACCOUNTACCOUNTSET = () => import(/* webpackChunkName: "accountSet" */ '../views/Account/AccountSet/AccountSet.mobile.vue');
// 个人账号-消息-交易
const ACCOUNTMESSAGESTRADE = () => import(/* webpackChunkName: "account" */ '../views/Account/Messages/components/TradeMessage.vue');
// 个人账号-消息-系统
const ACCOUNTMESSAGESSYSTEM = () => import(/* webpackChunkName: "account" */ '../views/Account/Messages/components/SystemMessage.vue');
// 个人账号-我收藏
const ACCOUNTMYFAVORITES = () => import(/* webpackChunkName: "account" */ '../views/Account/MyFavorites/MyFavorites.mobile.vue');
// 个人账号-优惠券
const ACCOUNTCOUPON = () => import(/* webpackChunkName: "account" */ '../views/Account/Coupon/Coupon.c2c.vue');
// 个人账号-流量联盟
const AFFILIATEPROGRAM = () => import(/* webpackChunkName: "accountAffiliate" */ '../views/Account/AffiliateProgram/AffiliateProgram.mobile.vue');
// 个人账号-流量联盟-主页
const AFFILIATEPROGRAMINDEX = () => import(/* webpackChunkName: "accountAffiliate" */ '../views/Account/AffiliateProgram/views/Index.mobile.vue');
// 个人账号-流量联盟-收入详情
const AFFILIATEINCOME = () => import(/* webpackChunkName: "accountAffiliate" */ '../views/Account/AffiliateProgram/PromotionIncome/components/Detail/Detail.Mobile.vue');
// 个人账号-流量联盟-推广用户
const AFFILIATEUSER = () => import(/* webpackChunkName: "accountAffiliate" */ '../views/Account/AffiliateProgram/PromotionIncome/components/User/User.Mobile.vue');
// 个人账号-流量联盟-我的客户
const AFFILIATEPROMOTERS = () => import(/* webpackChunkName: "accountAffiliate" */ '../views/Account/AffiliateProgram/PromotionIncome/components/Promoters/Promoters.Mobile.vue');
// 个人账号-流量联盟-我的推广者
const AFFILIATELEVEL = () => import(/* webpackChunkName: "accountAffiliate" */ '../views/Account/AffiliateProgram/Level/Level.mobile.vue');
// 个人账号-流量联盟-抽奖活动
const AFFILIATEAWARD = () => import(/* webpackChunkName: "accountAffiliate" */ '../views/Account/AffiliateProgram/Award/Award.mobile.vue');
// 个人账号-流量联盟-链接
const AFFILIATEPROGRAMLINK = () => import(/* webpackChunkName: "accountAffiliate" */ '../views/Account/AffiliateProgram/PromotionProfit/components/Link/Link.Edit.mobile.vue');
// 个人账号-流量联盟-活动
const AFFILIATEPROGRAMACTIVITY = () => import(/* webpackChunkName: "accountAffiliate" */ '../views/Account/AffiliateProgram/PromotionProfit/components/Amount/Amount.Activity.Edit.mobile.vue');
// 个人账号-流量联盟-通铺
const AFFILIATEPROGRAMHYBIRD = () => import(/* webpackChunkName: "accountAffiliate" */ '../views/Account/AffiliateProgram/AffiliateProgram.hybird.vue');
// 个人账号-流量联盟-引导
const AFFILIATEPROGRAMGUIDE = () => import(/* webpackChunkName: "accountAffiliate" */ '../views/Account/AffiliateProgram/views/Guide.mobile.vue');
// 个人账号-流量联盟-协议
const AFFILIATEPROGRAMAGREEMENT = () => import(/* webpackChunkName: "accountAffiliate" */ '../views/Account/AffiliateProgram/views/Agreement.vue');
// 商品详情页
const GOODSDETAIL = () => import(/* webpackChunkName: "lootbarGoods" */ '../views/Detail/Index.mobile.vue');

//  解决重复路由的报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};

const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function repalce(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch((err) => err);
};

VueRouter.prototype.START_STATE = window.history.state;

const routes = [
  // Market-首页
  {
    path: `${hreflangPrefix}/market`,
    name: 'Market.Index',
    redirect: `${hreflangPrefix}/market/all`,
    component: () => import(/* webpackChunkName: "market" */ '../views/Market/Index.mobile.vue'),
    meta: { filterCollapse: false },
    children: [{
      path: 'all',
      name: 'Market.All',
      component: () => import(/* webpackChunkName: "market" */ '../views/Market/AllItems.vue'),
      meta: { filterCollapse: false },
    },
    ],
  },
  // Market-商品详情页
  {
    path: `${hreflangPrefix}/market/goods/:goods_id`,
    name: 'Market.Goods',
    component: () => import(/* webpackChunkName: "market" */ '../views/Market/Goods/Index.vue'),
  },
  // Market-库存页
  {
    path: `${hreflangPrefix}/market/inventory`,
    name: 'Market.Inventory',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "inventory" */ '../views/Inventory/Index.vue'),
    meta: { filterCollapse: false },
  },
  // Market-购买
  {
    path: `${hreflangPrefix}/market/buy`,
    name: 'Market.Buy',
    redirect: `${hreflangPrefix}/market/buy/history`,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "buy" */ '../views/Buy/Index.vue'),
    children: [
      {
        path: 'history',
        name: 'Market.Buy.History',
        component: () => import(/* webpackChunkName: "buy" */ '../views/Buy/History/History.mobile.vue'),
        meta: { filterCollapse: true },
      },
    ],
  },
  // Market-出售
  {
    path: `${hreflangPrefix}/market/sell`,
    name: 'Market.Sell',
    redirect: `${hreflangPrefix}/market/sell/selling`,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "sell" */ '../views/Sell/Index.mobile.vue'),
    children: [
      {
        path: 'selling',
        name: 'Market.Sell.Selling',
        component: () => import(/* webpackChunkName: "sell" */ '../views/Sell/Selling/Selling.vue'),
        meta: { filterCollapse: false },
      },
      {
        path: 'history',
        name: 'Market.Sell.History',
        component: () => import(/* webpackChunkName: "sell" */ '../views/Sell/History/History.mobile.vue'),
        meta: { filterCollapse: true },
      },
    ],
  },
  // Market-店铺信息
  {
    path: `${hreflangPrefix}/market/user_store/:user_id`,
    redirect: `${hreflangPrefix}/market/user_store/:user_id/selling`,
    name: 'Market.UserStore',
    props: true,
    component: () => import(/* webpackChunkName: "userStore" */ '../views/Store/Index.mobile.vue'),
    children: [
      {
        path: 'selling',
        name: 'Market.UserStore.Selling',
        component: () => import(/* webpackChunkName: "userStore" */ '../views/Store/Selling/Selling.mobile.vue'),
      },
      {
        path: 'history',
        name: 'Market.UserStore.History',
        component: () => import(/* webpackChunkName: "userStore" */ '../views/Store/History/History.vue'),
      },
      {
        path: 'gallery',
        name: 'Market.UserStore.Gallery',
        component: () => import(/* webpackChunkName: "userStore" */ '../views/Store/Gallery/Gallery.mobile.vue'),
      },
    ],
  },
  // Market-个人账号信息
  {
    path: `${hreflangPrefix}/market/account`,
    name: 'Market.Account',
    redirect: '/market/account/profile',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "account" */ '../views/Account/Index.mobile.vue'),
    children: [
      // 钱包
      {
        path: 'balance',
        name: 'Market.Account.MyBalance',
        redirect: '/market/account/balance/deposit',
        component: () => import(/* webpackChunkName: "account" */ '../views/Account/Balance/Balance.mobile.vue'),
        meta: { loginRequired: true },
        children: [
          {
            path: 'deposit',
            name: 'Market.Account.MyBalance.Deposit',
            component: ACCOUNTMYBALANCEDEPOSIT,
            meta: { loginRequired: true },
          },
          {
            path: 'withdraw',
            name: 'Market.Account.MyBalance.Withdraw',
            component: ACCOUNTMYBALANCEWITHDRAW,
            meta: { loginRequired: true },
          },
          {
            path: 'history',
            name: 'Market.Account.MyBalance.History',
            component: ACCOUNTMYBALANCEHISTORY,
            meta: { loginRequired: true },
          },
        ],
      },
      // 账号设置
      {
        path: 'profile',
        name: 'Market.Account.AccountSet',
        component: ACCOUNTACCOUNTSET,
        meta: { loginRequired: true },
      },
      // 消息
      {
        path: 'messages',
        name: 'Market.Account.Messages',
        redirect: '/market/account/messages/trade',
        component: () => import(/* webpackChunkName: "account" */ '../views/Account/Messages/Messages.vue'),
        meta: { loginRequired: true },
        children: [
          {
            path: 'trade',
            name: 'Market.Account.Messages.Trade',
            component: ACCOUNTMESSAGESTRADE,
            meta: { loginRequired: true },
          },
          {
            path: 'system',
            name: 'Market.Account.Messages.System',
            component: ACCOUNTMESSAGESSYSTEM,
            meta: { loginRequired: true },
          },
        ],
      },
      // 我的收藏
      {
        path: 'favorites',
        name: 'Market.Account.MyFavorites',
        component: ACCOUNTMYFAVORITES,
        meta: { loginRequired: true },
      },
      // 优惠券
      {
        path: 'coupon',
        name: 'Market.Account.Coupon',
        component: () => import(/* webpackChunkName: "account" */ '../views/Account/Coupon/Coupon.vue'),
        meta: { loginRequired: true },
      },
      // 反馈
      {
        path: 'support',
        name: 'Market.Account.Feedback',
        component: () => import(/* webpackChunkName: "account" */ '../views/Account/Feedback/Feedback.mobile.vue'),
        meta: { loginRequired: true },
        children: [
          {
            path: 'detail',
            name: 'Market.Account.Feedback.Detail',
            component: () => import(/* webpackChunkName: "account" */ '../views/Account/Feedback/FeedbackDetail/FeedbackDetail.mobile.vue'),
            meta: { loginRequired: true },
          },
        ],
      },
    ],
  },
  // Market-faq
  {
    path: `${hreflangPrefix}/market/faq`,
    name: 'Market.Faq',
    component: FAQ,
  },
  // Market-maintenance
  {
    path: `${hreflangPrefix}/market/maintenance`,
    name: 'Market.Maintenance',
    component: () => import(/* webpackChunkName: "common" */ '../views/Error/Maintenance.vue'),
  },
  // 原库存页路由放至market/inventory
  {
    path: `${hreflangPrefix}/inventory`,
    redirect: `${hreflangPrefix}/market/inventory`,
  },
  // 原购买路由放至market/buy
  {
    path: `${hreflangPrefix}/buy*`,
    redirect: `${hreflangPrefix}/market/buy*`,
  },
  // 原出售路由放至market/sell
  {
    path: `${hreflangPrefix}/sell`,
    redirect: `${hreflangPrefix}/market/sell`,
  },
  {
    path: `${hreflangPrefix}/sell/*`,
    redirect: `${hreflangPrefix}/market/sell/*`,
  },
  // 原店铺路由放至market/user_store
  {
    path: `${hreflangPrefix}/user_store/:user_id/*`,
    redirect: `${hreflangPrefix}/market/user_store/:user_id/*`,
  },
  {
    path: `${hreflangPrefix}/account/messages/*`,
    redirect: `${hreflangPrefix}/market/account/messages/*`,
  },
  /* -------------------------------- Loot Bar -------------------------------- */
  // LootBar-首页
  {
    path: `${hreflangPrefix}/`,
    name: 'Index',
    component: () => import(/* webpackChunkName: "lootbar" */ '../views/Index/Index.mobile.vue'),
  },
  // LootBar-首页
  {
    path: `${hreflangPrefix}/index`,
    redirect: `${hreflangPrefix}/`,
  },
  // LootBar-搜索
  {
    path: `${hreflangPrefix}/search`,
    name: 'Search',
    component: () => import(/* webpackChunkName: "lootbar" */ '../views/Search/Index.mobile.vue'),
    meta: { title: 'lootBar.search' },
  },
  {
    path: `${hreflangPrefix}/rule/:type`,
    name: 'RulePage',
    component: () => import(/* webpackChunkName: "lootbar" */ '../views/RulePage/Index.mobile.vue'),
  },
  // LootBar-充值页面
  {
    path: `${hreflangPrefix}/topup/:game`,
    redirect: `${hreflangPrefix}/top-up/:game`,
  },
  {
    path: `${hreflangPrefix}/top-up/:game`,
    name: 'Topup',
    component: () => import(/* webpackChunkName: "lootbarTopup" */ '../views/Topup/Index.mobile.vue'),
    meta: { title: 'topup.title', editPrefer: true },
    props: true,
  },
  // lootBar-列表页
  {
    path: `${hreflangPrefix}/list/:game/:goodsType`,
    redirect: (to) => {
      const { goodsType, game } = to.params;
      return {
        name: 'List',
        params: {
          game,
          goodsType: GOODSTYPE2ROUTERMAP[goodsType],
        },
      };
    },
  },
  {
    path: `${hreflangPrefix}/:goodsType/:game/comment`,
    name: 'CommentList',
    component: () => import(/* webpackChunkName: "commentList" */ '../views/comment/Index.mobile.c2c.vue'),
    meta: { title: 'evaluate.own_evaluate', editPrefer: true },
    props: true,
  },
  {
    path: `${hreflangPrefix}/:goodsType(new-account|cd-key)/:game`,
    name: 'List',
    component: () => import(/* webpackChunkName: "lootbarList" */ '../views/List/Index.mobile.vue'),
    meta: { title: 'lootBar_list.title', editPrefer: true },
    props: true,
  },
  // lootBar-商品详情页
  {
    path: `${hreflangPrefix}/:goodsType(new-account|cd-key)/:game/:sellorderid`,
    name: 'Detail',
    component: GOODSDETAIL,
    meta: { title: 'lootBar_detail.title' },
    props: true,
  },
  {
    path: `${hreflangPrefix}/game-coins/:game`,
    name: 'Coin',
    component: () => import(/* webpackChunkName: "lootbarCoin" */ '../views/Coin/Index.mobile.vue'),
    meta: { title: 'coin.coin_trade' },
    props: true,
  },
  {
    path: `${hreflangPrefix}/game-items/:game`,
    name: 'Gameitem',
    component: () => import(/* webpackChunkName: "lootbarGameitem" */ '../views/Gameitem/Index.mobile.vue'),
    meta: { title: 'lootBar.game_items' },
    props: true,
  },
  {
    path: `${hreflangPrefix}/game-sbc/:game`,
    name: 'GameSbc',
    component: () => import(/* webpackChunkName: "lootbarSbc" */ '../views/GameSbc/Index.mobile.vue'),
    meta: { title: 'FC25 SBC' },
    props: true,
  },
  {
    path: `${hreflangPrefix}/utils/genshin-impact`,
    name: 'Utils.GenshinImpact',
    component: () => import(/* webpackChunkName: "utilsGenshinImpact" */ '../views/Utils/GenshinImpact/Index.vue'),
  },
  // community
  {
    path: `${hreflangPrefix}/Community`,
    name: 'Community',
    redirect: '/Community/Discord',
    component: () => import(/* webpackChunkName: "lootbarCommunity" */ '../views/Community/Discord/Index.mobile.vue'),
    children: [
      {
        path: 'Discord',
        name: 'Community.Discord',
        component: () => import(/* webpackChunkName: "lootbarCommunity" */ '../views/Community/Discord/Index.mobile.vue'),
      },
    ],
  },
  // LootBar-消息中心
  {
    path: `${hreflangPrefix}/messages`,
    name: 'Messages',
    meta: { loginRequired: true },
    component: () => import(/* webpackChunkName: "lootbarMessage" */ '../views/Messages/Index.mobile.vue'),
    children: [
      {
        path: 'trade',
        name: 'Messages.Trade',
        component: ACCOUNTMESSAGESTRADE,
        meta: { loginRequired: true, title: 'messages.trade_messages' },
      },
      {
        path: 'system',
        name: 'Messages.System',
        component: ACCOUNTMESSAGESSYSTEM,
        meta: { loginRequired: true, title: 'messages.system_messages' },
      },
      {
        path: 'chat',
        name: 'Messages.Chat',
        component: () => import(/* webpackChunkName: "lootbarMessage" */ '../views/Messages/Chat/Index.mobile.vue'),
        meta: { loginRequired: true },
        children: [
          {
            path: ':type/:toId',
            name: 'Messages.Chat.Conversation',
            component: () => import(/* webpackChunkName: "lootbarMessage" */ '../views/Messages/Chat/Conversation.mobile.vue'),
            meta: { loginRequired: true, title: 'messages.seller_messages' },
          },
        ],
      },
    ],
  },
  // LootBar-我的页面
  {
    path: `${hreflangPrefix}/my`,
    name: 'My',
    component: () => import(/* webpackChunkName: "lootbar" */ '../views/My/Index.mobile.vue'),
    meta: { loginRequired: true },
  },
  // LootBar-个人账号信息
  {
    path: `${hreflangPrefix}/account`,
    name: 'Account',
    redirect: '/account/profile',
    component: () => import(/* webpackChunkName: "account" */ '../views/Account/Index.mobile.c2c.vue'),
    children: [
      // 钱包
      {
        path: 'balance',
        name: 'Account.MyBalance',
        redirect: '/account/balance/deposit',
        component: () => import(/* webpackChunkName: "account" */ '../views/Account/Balance/Balance.mobile.c2c.vue'),
        meta: { loginRequired: true, title: 'account.my_balance', locale: 'account.my_balance' },
        children: [
          {
            path: 'deposit',
            name: 'Account.MyBalance.Deposit',
            component: ACCOUNTMYBALANCEDEPOSIT,
            meta: { loginRequired: true, title: 'account.my_balance' },
          },

          {
            path: 'withdraw',
            name: 'Account.MyBalance.Withdraw',
            component: ACCOUNTMYBALANCEWITHDRAW,
            meta: { loginRequired: true, title: 'account.my_balance' },
          },
          {
            path: 'history',
            name: 'Account.MyBalance.History',
            component: ACCOUNTMYBALANCEHISTORY,
            meta: { loginRequired: true, title: 'account.my_balance' },
          },
        ],
      },
      // 积分
      {
        path: 'point',
        name: 'Account.MyPoint',
        redirect: '/account/point/task',
        component: () => import(/* webpackChunkName: "accountPoint" */ '../views/Account/Point/Point.mobile.vue'),
        meta: { loginRequired: true, title: 'account.point_my', locale: 'account.point_my' },
        children: [
          {
            path: 'task',
            name: 'Account.MyPoint.Task',
            component: ACCOUNTPOINTTASK,
            meta: { loginRequired: true, title: 'account.point_my', locale: 'account.point_tab_task' },
          },

          {
            path: 'exchange',
            name: 'Account.MyPoint.Redeem',
            component: ACCOUNTPOINTREDEEM,
            meta: { loginRequired: true, title: 'account.point_my', locale: 'account.point_tab_redeem' },
          },
          {
            path: 'log',
            name: 'Account.MyPoint.Log',
            component: ACCOUNTPOINTLOG,
            meta: { loginRequired: true, title: 'account.point_my', locale: 'account.point_tab_log' },
          },
        ],
      },
      {
        path: 'buy',
        name: 'Account.Buy',
        component: () => import(/* webpackChunkName: "accountBuy" */ '../views/Account/Buy/Index.mobile.c2c.vue'),
        meta: { loginRequired: true, title: 'buy.history', locale: 'buy.history' },
        children: [
          {
            path: 'order/:orderId',
            name: 'Account.Buy.Order',
            component: () => import(/* webpackChunkName: "accountBuy" */ '../views/Account/Buy/Order.mobile.c2c.vue'),
            meta: { loginRequired: true, title: 'lootBar.order_details' },
            props: true,
          },
          {
            path: 'coin/:game/:id',
            name: 'Account.Buy.Coin',
            component: () => import(/* webpackChunkName: "accountBuy" */ '../views/Account/Buy/Coin.mobile.c2c.vue'),
            meta: { loginRequired: true, title: 'coin.submit_info' },
            props: true,
          },
        ],
      },
      // 账号设置
      {
        path: 'profile',
        name: 'Account.AccountSet',
        component: () => import(/* webpackChunkName: "account" */ '../views/Account/AccountSet/AccountSet.mobile.c2c.vue'),
        meta: { loginRequired: true, title: 'account.profile', locale: 'account.profile' },
        children: [
          {
            path: 'game',
            name: 'Account.AccountSet.Game',
            component: () => import(/* webpackChunkName: "accountSet" */ '../views/Account/AccountSet/Game/Index.mobile.c2c.vue'),
            meta: { loginRequired: true, title: 'profile.game_account_settings' },
          },
          {
            path: 'account',
            name: 'Account.AccountSet.Account',
            component: () => import(/* webpackChunkName: "accountSet" */ '../views/Account/AccountSet/Profile/Index.mobile.c2c.vue'),
            meta: { loginRequired: true, title: 'profile.account_info' },
          },
          {
            path: 'email',
            name: 'Account.AccountSet.Email',
            component: () => import(/* webpackChunkName: "accountSet" */ '../views/Account/AccountSet/Profile/Email.mobile.c2c.vue'),
            meta: { loginRequired: true, title: 'profile.email_bind' },
          },
          {
            path: 'nickname',
            name: 'Account.AccountSet.Nickname',
            component: () => import(/* webpackChunkName: "accountSet" */ '../views/Account/AccountSet/Profile/Nickname.mobile.c2c.vue'),
            meta: { loginRequired: true, title: 'profile.modify' },
          },
        ],
      },
      // 货币语言
      {
        path: 'prefer',
        name: 'Account.Prefer',
        component: () => import(/* webpackChunkName: "accountSet" */ '../views/Account/Prefer/Index.mobile.c2c.vue'),
        meta: { loginRequired: true, title: 'profile.language_currency', locale: 'profile.language_currency' },
      },
      {
        path: 'coupon',
        name: 'Account.Coupon',
        component: ACCOUNTCOUPON,
        meta: { loginRequired: true, title: 'account.coupon', locale: 'account.coupon' },
      },
      // 反馈
      {
        path: 'support',
        name: 'Account.Feedback',
        component: () => import(/* webpackChunkName: "account" */ '../views/Account/Feedback/Feedback.mobile.c2c.vue'),
        meta: { loginRequired: true, title: 'support.ticket_records', locale: 'support.ticket_records' },
        children: [
          {
            path: 'detail',
            name: 'Account.Feedback.Detail',
            component: () => import(/* webpackChunkName: "account" */ '../views/Account/Feedback/FeedbackDetail/FeedbackDetail.mobile.c2c.vue'),
            meta: { loginRequired: true, title: 'support.ticket_records' },
          },
        ],
      },
      // Affiliate Program
      {
        path: 'affiliate',
        name: 'Account.AffiliateProgram.Default',
        component: AFFILIATEPROGRAM,
        meta: { loginRequired: true, title: 'account.affiliate_program', locale: 'account.affiliate_program' },
        children: [
          {
            path: '',
            name: 'Account.AffiliateProgram',
            component: AFFILIATEPROGRAMINDEX,
            meta: { loginRequired: true, title: 'account.affiliate_program' },
            redirect: '/account/affiliate/income',
            children: [
              {
                path: 'income',
                name: 'Account.AffiliateProgram.Income',
                meta: { loginRequired: true, title: 'account.affiliate_program' },
                component: AFFILIATEINCOME,
              },
              {
                path: 'user',
                name: 'Account.AffiliateProgram.User',
                meta: { loginRequired: true, title: 'account.affiliate_program' },
                component: AFFILIATEUSER,
              },
              {
                path: 'promoters',
                name: 'Account.AffiliateProgram.Promoters',
                meta: { loginRequired: true, title: 'account.affiliate_program' },
                component: AFFILIATEPROMOTERS,
              },
            ],
          },
          {
            path: 'level',
            name: 'Account.AffiliateProgram.Level',
            component: AFFILIATELEVEL,
            meta: { loginRequired: true, title: 'account.affiliate_level_my', locale: 'account.affiliate_level_my' },
          },
          // Affiliate Program Link
          {
            path: 'link',
            name: 'Account.AffiliateProgram.Link',
            component: AFFILIATEPROGRAMLINK,
            meta: { loginRequired: true, title: 'account.affiliate_program' },
          },
          // Affiliate Program activity
          {
            path: 'activity',
            name: 'Account.AffiliateProgram.Activity',
            component: AFFILIATEPROGRAMACTIVITY,
            meta: { loginRequired: true, title: 'account.affiliate_program' },
          },
          // Affiliate Program Agreement
          {
            path: 'agreement',
            name: 'Account.AffiliateProgram.Agreement',
            component: AFFILIATEPROGRAMAGREEMENT,
            meta: { loginRequired: true, title: 'account.apply_affiliate_program', locale: 'account.apply_affiliate_program' },
          },
        ],
      },
      // 关于我们
      {
        path: 'aboutus',
        name: 'Account.Aboutus',
        component: () => import(/* webpackChunkName: "account" */ '../views/Account/Aboutus/Index.mobile.c2c.vue'),
        meta: { title: 'nav.about_us', locale: 'nav.about_us' },
      },
    ],
  },
  // 流量联盟-通铺
  {
    path: `${hreflangPrefix}/affiliate`,
    name: 'AffiliateProgram',
    component: AFFILIATEPROGRAMHYBIRD,
    redirect: `${hreflangPrefix}/affiliate/guide`,
    children: [
      {
        path: 'guide',
        name: 'AffiliateProgram.Guide',
        component: AFFILIATEPROGRAMGUIDE,
      },
      {
        path: 'agreement',
        name: 'AffiliateProgram.Agreement',
        component: AFFILIATEPROGRAMAGREEMENT,
      },
      {
        path: 'award/:id',
        name: 'AffiliateProgram.Award',
        component: AFFILIATEAWARD,
      },
    ],
  },
  // 支付页
  {
    path: `${hreflangPrefix}/payment`,
    name: 'Payment',
    component: () => import(/* webpackChunkName: "payment" */ '../views/Payment/Index.mobile.vue'),
    meta: { title: 'nav.payment' },
  },
  // 支付结果页
  {
    path: `${hreflangPrefix}/payment/:billOrderId`,
    name: 'Payment.Result',
    component: () => import(/* webpackChunkName: "payment" */ '../views/Payment/Result.mobile.vue'),
    meta: { loginRequired: true },
    props: true,
  },
  // LootBar-帮助
  {
    path: `${hreflangPrefix}/faq`,
    name: 'Faq',
    component: FAQ,
    meta: { title: 'nav.faq' },
  },
  {
    path: `${hreflangPrefix}/:game`,
    name: 'GamePage',
    component: () => import(/* webpackChunkName: "lootbarGamePage" */ '../views/GamePage/Index.mobile.vue'),
    props: true,
  },
  /* -------------------------------- 400 头部使用LootBar -------------------------------- */
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "common" */ '../views/Error/404.vue'),
  },
];

if (process.env.NODE_ENV !== 'production') {
  routes.push({
    path: `${hreflangPrefix}/debug/request`,
    name: 'Request',
    component: () => import(/* webpackChunkName: "test" */ '../views/Test/Request.vue'),
  });
  routes.push({
    path: `${hreflangPrefix}/debug/components`,
    name: 'Components',
    component: () => import(/* webpackChunkName: "test" */ '../views/Test/Components.vue'),
  });
}

// 非生产环境，或者测试环境，添加该路由
if (process.env.VUE_APP_DEPLOY_ENV === 'test' || process.env.NODE_ENV !== 'production') {
  routes.push({
    path: `${hreflangPrefix}/debug/native`,
    name: 'Native',
    component: () => import(/* webpackChunkName: "test" */ '../views/Test/Native.vue'),
  });
}

// app内嵌的直购页面
if (isInBuffClient()) {
  routes.push({
    path: `${hreflangPrefix}/app/buy`,
    name: 'App.Buy',
    component: () => import(/* webpackChunkName: "app" */ '../views/App/Buy.vue'),
  });
  routes.push({
    path: `${hreflangPrefix}/app/buy2`,
    name: 'App.Buy2',
    component: () => import(/* webpackChunkName: "app" */ '../views/App/Buy.vue'),
  });
}

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach(beforeEachHook);
router.afterEach((to) => {
  window.scrollTo(0, 0);
  Vue.nextTick(() => {
    const title = to.meta.title ? i18n.t(to.meta.title) : '';
    bus.$emit('nav.title.set', title);
  });
});

export default router;
