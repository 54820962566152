var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"reference",class:[
    'w-select',
    `w-select--${_vm.size}`,
    {
      'is-disabled': _vm.selectDisabled,
      'is-reating': _vm.selectReating,
      'is-dropdown-hover': _vm.hover || _vm.showSubDropdown,
      'w-select--selected': _vm.isSelected,
      'w-select--active': _vm.active,
      [`w-select--${_vm.theme}`]: _vm.theme !== 'light',
    },
  ],attrs:{"tabindex":"0"}},[_c('div',{staticClass:"w-select__wrap",class:{'is-placeholder': !_vm.isSelected},on:{"click":_vm.handleToggleActive}},[_c('div',{staticClass:"w-select__inner"},[(!_vm.isSelected)?[_vm._v(_vm._s(_vm.placeholder))]:[(_vm.$scopedSlots.default)?[_vm._t("default",null,{"selected":_vm.selected})]:(_vm.multiple)?[_vm._v(" "+_vm._s(_vm.selected.map((item) => item.label).join(', '))+" ")]:[_vm._v(" "+_vm._s(_vm.selected.label)+" ")]]],2),(!_vm.selectDisabled && _vm.clearable && _vm.isSelected)?_c('w-button',{staticClass:"w-select__clear",attrs:{"icon":"","name":"icon_home_clearfilter","icon-size":12},on:{"click":function($event){$event.stopPropagation();return _vm.handleClear.apply(null, arguments)}},nativeOn:{"mousedown":function($event){$event.preventDefault();}}}):_vm._e(),_c('i',{staticClass:"w-select__icon"})],1),_c('SelectDropdown',{directives:[{name:"show",rawName:"v-show",value:(_vm.active),expression:"active"}],ref:"popper",staticClass:"w-select-dropdown",class:[
      _vm.type ? `w-select-dropdown--${_vm.type}` : '',
      { 'theme-dark': _vm.theme === 'dark' }],attrs:{"offset":[0, 8]},nativeOn:{"mouseover":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false}}},[_c('w-scrollbar',{style:(_vm.style),attrs:{"prevent-bar":_vm.showSubDropdown,"wrap-class":"w-select-dropdown__wrap","auto-height":""}},[_vm._l((_vm.options),function(option,index){return [(option.key !== undefined)?_c('div',{key:option.key,staticClass:"w-select-dropdown__item",class:{'is-hover': _vm.showSubDropdown && _vm.hoverOption.key === option.key},on:{"click":function($event){return _vm.handleSelect(option)},"mouseenter":function($event){if($event.target !== $event.currentTarget)return null;return _vm.handleMouseenterOption(option, $event)},"mouseleave":function($event){if($event.target !== $event.currentTarget)return null;return _vm.handleMouseoutOption()}}},[(_vm.$scopedSlots.option)?_vm._t("option",null,{"option":option}):[_vm._v(" "+_vm._s(option.label)+" "),(option.sub)?_c('i',{staticClass:"w-select__icon"}):_vm._e()]],2):(option.label)?_c('div',{key:option.label,staticClass:"w-select-dropdown__label"},[(_vm.$scopedSlots.label)?_vm._t("label",null,{"option":option}):[_vm._v(_vm._s(option.label))]],2):_c('hr',{key:`hr-${index}`,staticClass:"w-select-dropdown__hr"})]})],2)],1),(_vm.showSubDropdown)?_c('SelectSubDropdown',{ref:"subPopper",attrs:{"options":_vm.hoverOption.sub,"reference":_vm.hoverEl},on:{"select":_vm.handleSelect},nativeOn:{"mouseenter":function($event){_vm.hoverSubDropdown = true},"mouseleave":function($event){_vm.hoverSubDropdown = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }