import { generateUrl as generateUrl2 } from './utils';

// 购买历史 求购记录 供应商品
export default {
  postMarketBuyOrderPsNotified() {
    return generateUrl2('/api/market/buy_order/ps_notified', false);
  },
  postMarketBuyOrderDelete() {
    return generateUrl2('/api/market/buy_order/delete', false);
  },
  getMarketBuyHistoryNew() {
    return generateUrl2('/api/v2/market/bill_order/', false);
  },
  getMarketBuyDetailProgress() {
    return generateUrl2('/api/v2/market/bill_order/progress', false);
  },
  getMarketBuyDetailNew() {
    return generateUrl2('/api/v2/market/bill_order/detail', false);
  },
  getMarketBuyHistory() {
    return generateUrl2('/api/market/buy_order/history', false);
  },
  getMarketBuyOrderFc24Extra() {
    return generateUrl2('/api/market/buy_order/fc24/extra', false);
  },
  getMarketBuyDetail() {
    return generateUrl2('/api/market/buy_order/detail', false);
  },
  getMarketBuyOrderCancel() {
    return generateUrl2('/api/market/buy_order/cancel', false);
  },
  postMarketBuySubmit() {
    return generateUrl2('/api/market/goods/buy/submit', false);
  },
  getMarketBuySubmit() {
    return generateUrl2('/api/market/goods/buy/submit', false);
  },
  getMarketGoodsBuyCalculate() {
    return generateUrl2('/api/market/goods/buy/calculate', false);
  },
  getMarketGoodsBuyCartCalculate() {
    return generateUrl2('/api/market/goods/buy_cart/calculate', false);
  },
  // getMarketBuyOrder() {
  //   return generateUrl('/api/market/buy_order/records', false);
  // },
  // cancelMarketOrder() {
  //   return generateUrl('/api/market/buy_order/cancel', false);
  // },
  // getMarketBillOrder() {
  //   return generateUrl('/api/market/buy_order/supplied/bill_orders', false);
  // },
  // getMarketOrderSpecificList() {
  //   return generateUrl('/api/market/buy_order/specific_list', false);
  // },
  // getMarketOrderSpecificPrice() {
  //   return generateUrl('/api/market/buy_order/specific_preview', false);
  // },
  // getMarketBuyOrderPreview() {
  //   return generateUrl('/api/market/buy_order/create/preview', false);
  // },
  // createMarketOrder() {
  //   return generateUrl('/api/market/buy_order/create', false);
  // },
  // getMarketSupplyList() {
  //   return generateUrl('/api/market/goods/supply/preview/manual/v2', false);
  // },
  // getPartnerSupplyList() {
  //   return generateUrl('/api/market/goods/supply/preview/partner/v2', false);
  // },
  // createMarketSupply() {
  //   return generateUrl('/api/market/goods/supply/manual_plus', false);
  // },
  // createPartnerSupply() {
  //   return generateUrl('/api/market/goods/supply/partner', false);
  // },
  preview() {
    return generateUrl2('/api/market/goods/buy/preview', false);
  },
  getMarketBuyPreview() {
    return generateUrl2('/api/market/goods/buy/preview', false);
  },
  postMarketBuyPreview() {
    return generateUrl2('/api/market/goods/buy/preview', false);
  },
  postMarketBuy() {
    return generateUrl2('/api/market/goods/buy', false);
  },
  postMarketBuyCart() {
    return generateUrl2('/api/market/goods/buy_cart', false);
  },
  postMarketBuyCartPreview() {
    return generateUrl2('/api/market/goods/buy_cart/preview', false);
  },
  postMarketCashierPreview() {
    return generateUrl2('/api/market/goods/cashier/preview', false);
  },
  postMarketCashierCartPreview() {
    return generateUrl2('/api/market/goods/cashier/cart_preview', false);
  },
  postModifyComment() {
    return generateUrl2('/api/market/order_comment/modify', false);
  },
  getMarketBuyOrderState() {
    return generateUrl2('/api/market/buy_order/state', false);
  },
};
