<template>
  <div :class="
    ['c-autocomplete-dropdown-inner',
     isSmall? 'modal-page' : 'pc-page',
     isMobile? 'mobile-page' : ''
    ]">
    <div v-if="loading" class="c-loading">
      <Loading />
    </div>

    <Empty v-else-if="empty" :title="$t('lootBar.not_search_result')" />

    <div v-else>
      <div class="c-result">
        <!-- 游戏部分 -->
        <div v-if="result.length" class="c-search-content game">
          <div class="c-result_title">
            {{ $t("lootBar.game") }}
          </div>
          <div v-for="item in result" :key="item.id" class="c-result-game">
            <div class="c-result-game_title">
              {{ item.desc }}
            </div>
            <div class="c-result-game_content">
              <ul v-if="item.on_sell_services && item.on_sell_services.length">
                <li
                  v-for="items in item.on_sell_services"
                  :key="items.service_type"
                  @mousedown.prevent
                  @click="hangderGame(item, items.service_type)">
                  <div class="game-icon"
                    :style="`background-image: url(${tabList[items.service_type]?.icon})`"></div>
                  <div class="game-title">{{ $t(tabList[items.service_type]?.name) }}</div>
                </li>
              </ul>
              <ul v-else-if="item.service && item.service.length">
                <li
                  v-for="items in item.service"
                  :key="items"
                  @mousedown.prevent
                  @click="hangderGame(item, items)">
                  <div class="game-icon"
                    :style="`background-image: url(${tabList[items]?.icon})`"></div>
                  <div class="game-title">{{ $t(tabList[items]?.name) }}</div>
                </li>
              </ul>
            </div>
          </div>
          <div v-if="isLoadingGameMore" class="c-loading-more">
            <w-spinner size="m" theme="primary" />
          </div>
        </div>
        <!-- 商品部分 -->
        <div v-if="goodsResult.length" class="c-search-content offer">
          <div class="c-result_title">
            {{ $t("lootBar.offers") }}
          </div>
          <div class="c-result-offer" @mousedown.prevent>
            <router-link
              v-for="item in goodsResult"
              :key="item.id"
              :to="$_localePath(setLink(item))">
              <HomeCell
                type="search"
                :info="item"
                :keyword="keyword"
                @click="goShopDetailLog(item)" />
            </router-link>
          </div>
          <div v-if="isLoadingMore" class="c-loading-more">
            <w-spinner size="m" theme="primary" />
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import HomeCell from '@/components/Home/HomeCell.vue';
import Loading from '@/components/Loading.vue';
import Empty from '@/views/components/LootbarEmpty.vue';
import bus from '@/utils/bus';
import { isMobile } from '@/utils';
import GENRE from '@/enums/genre';
import {
  TABNAME, GOODSTYPE, SELLORDERFILTERKEY, GOODSTYPE2ROUTERMAP, GOODSTYPENUM,
} from '@/enums';

let timer = null;

export default {
  components: {
    HomeCell,
    Loading,
    Empty,
  },
  props: {
    isSmall: Boolean,
  },
  data() {
    return {
      tabList: GENRE,
      keyword: '',
      loading: false,
      isLoadingMore: false,
      isLoadingGameMore: false,
      empty: false,
      result: [],
      goodsResult: [],
      loaded: false,
      gameLoaded: false,
      goodsInfo: {},
      // 翻页加载
      params: {
        page_size: 30,
        page_num: 1,
      },
      // game翻页加载
      paramsGame: {
        page_size: 30,
        page_num: 1,
      },
    };
  },
  created() {
    bus.$on('search.result', async (val) => {
      if (timer || !val) clearTimeout(timer);
      this.params.page_num = 1;
      this.paramsGame.page_num = 1;
      this.result = [];
      this.goodsResult = [];
      this.empty = false;
      this.loading = true;
      this.loaded = false;
      this.gameLoaded = false;
      timer = setTimeout(async () => {
        // 更新输入的关键字
        this.keyword = val;
        const [result, goodsResult] = await this.searchResult();
        this.loading = false;

        this.result = result.items;
        this.goodsResult = goodsResult.items;
        this.goodsInfo = goodsResult.goods_info;

        if (this.result.length === 0 && this.goodsResult.length === 0) {
          this.empty = true;
          this.loaded = true;
          this.gameLoaded = true;
        }
        this.checkIsLoaded(goodsResult.total_count);
        this.checkGameIsLoaded(result.total_count);

        this.$logger('home_c2c_market_search_click', { keyword: val });
      }, 800);
    });
  },
  methods: {
    setLink(item) {
      const type = this.goodsInfo[item.goods_id]?.type;
      if (type === GOODSTYPENUM.ITEMTRADE) {
        const attribute = item?.asset_info?.attribute;
        let platform = null;
        let selectList = null;
        if (attribute && attribute.length) {
          platform = attribute.find((seacrchItem) => seacrchItem.key === 'platform');
          selectList = attribute.filter((seacrchItem) => {
            const { show_options: showOptions } = seacrchItem;
            return showOptions.includes('select');
          });
          if (selectList && selectList.length) {
            selectList = selectList.reduce((pre, plat) => ({ ...pre, [plat.key]: plat.value }), {});
          }
        }
        const { href } = this.$router.resolve({
          name: 'Gameitem',
          params: {
            game: item.app_game,
          },
          query: {
            platform: platform?.value,
            keyword: item.asset_info.info.asset_name,
            ...selectList && selectList,
          },
        });
        return href;
      } if (type === GOODSTYPENUM.SBC) {
        const attribute = item?.asset_info?.attribute;
        let platform = null;
        if (attribute && attribute.length) {
          platform = attribute.find((seacrchItem) => seacrchItem.key === 'platform');
        }
        const { href } = this.$router.resolve({
          name: 'GameSbc',
          params: {
            game: item.app_game,
          },
          query: {
            assetid: item.asset_info.id,
            platform: platform?.value,
          },
        });
        return href;
      }
      const { href } = this.$router.resolve({
        name: 'Detail',
        params: {
          game: item.app_game,
          goodsType: GOODSTYPE2ROUTERMAP[type],
          sellorderid: item.id,
        },
      });
      return href;
      // return `/${GOODSTYPE2R
      // OUTERMAP[this.goodsInfo[item.goods_id]?.type]}/${item.app_game}/${item.id}`;
    },
    // 检测商品是否加载完毕
    checkIsLoaded(count) {
      if (Number(count) === this.goodsResult.length) this.loaded = true;
    },
    // 检测游戏是否加载完毕
    checkGameIsLoaded(count) {
      if (Number(count) === this.result.length) this.gameLoaded = true;
    },
    handleScroll(e) {
      if (this.loading
      || this.isLoadingGameMore || this.isLoadingMore || (this.loaded && this.gameLoaded)) return;
      const { scrollHeight, scrollTop, clientHeight } = e;
      if (scrollHeight - (scrollTop + 100) < clientHeight) {
        this.loadMore();
      }
    },
    goShopDetailLog(item) {
      this.$logger('page_searchresult_click', { appname: item.app_name, assetid: item.asset_info.assetid });
    },
    async hangderGame(item, tabName) {
      this.$logger('page_searchresult_click', { appname: item.desc });
      if (tabName === TABNAME.TOPUP) {
        this.$router.push(this.$_localePath({ name: 'Topup', params: { game: item.name } }));
      } else if (tabName === TABNAME.COINTRADE) {
        this.$router.push(this.$_localePath({ name: 'Coin', params: { game: item.name } }));
      } else if (tabName === TABNAME.ASSET) {
        this.$router.push(this.$_localePath({ path: '/market/all' }));
      } else if (tabName === TABNAME.ITEMTRADE) {
        this.$router.push(this.$_localePath({ name: 'Gameitem', params: { game: item.name } }));
      } else if (tabName === TABNAME.SBC) {
        this.$router.push(this.$_localePath({ name: 'GameSbc', params: { game: item.name } }));
      } else {
        if (tabName === TABNAME.ACCOUNT) {
          const {
            [SELLORDERFILTERKEY.SUBGAMENAME]: subgameName = '',
          } = this.$route.query;
          const data = await this.$store.dispatch('getMarketGoodsSellOrder', {
            game: item.name,
            goods_type: GOODSTYPE.ACCOUNT,
            subgame_name: subgameName.length ? subgameName : undefined,
          });
          if (data.total_count === 1) {
            // 只有一个跳转详情页
            this.$router.push(this.$_localePath({
              name: 'Detail',
              params: {
                game: data.items[0].app_game,
                goodsType: GOODSTYPE2ROUTERMAP[data.goods_info[data.items[0].goods_id].type],
                sellorderid: data.items[0].id,
              },
            }));
            return;
          }
        }
        this.$router.push(this.$_localePath({
          name: 'List',
          params: {
            game: item.name,
            goodsType: GOODSTYPE2ROUTERMAP[this.tabList[tabName].id],
          },
        }));
      }
    },
    async loadMore() {
      if (!this.gameLoaded) {
        this.isLoadingGameMore = true;
        this.paramsGame.page_num += 1;
        const goodsResult = await this.searchGame();
        this.isLoadingGameMore = false;
        this.result.push(...goodsResult.items);
        this.checkGameIsLoaded(goodsResult.total_count);
        return;
      }
      this.isLoadingMore = true;
      this.params.page_num += 1;
      const goodsResult = await this.searchGoods();
      this.isLoadingMore = false;
      this.goodsResult.push(...goodsResult.items);
      this.goodsInfo = { ...this.goodsInfo, ...goodsResult.goods_info };
      this.checkIsLoaded(goodsResult.total_count);
    },
    // 全部搜索
    searchResult() {
      return Promise.all([
        this.searchGame(),
        this.searchGoods(),
      ]);
    },
    searchGame() {
      return this.$store.dispatch('getGameList', { search_str: this.keyword, ...this.paramsGame });
    },

    searchGoods() {
      return this.$store.dispatch('getMarketGoodsSellOrder', {
        search_keyword: this.keyword, goods_type: '2|3|6|7', game: 'main', ...this.goodsParams, ...this.params,
      });
    },
  },
  computed: {
    ...mapGetters(['currencySymbol']),
    isMobile() {
      return isMobile();
    },
  },
};
</script>

<style lang="less" scoped>
.c-autocomplete-dropdown-inner {
  user-select: none;
  color: @label_light_1;

  // 新的搜索
  .c-result {

    &_title {
      // margin: 0 16px;
      padding: 12px 16px;
      font-weight: 600;
      font-size: 15px;
      line-height: 23px;
      border-bottom: 0.5px solid #ebecf0;
      background: @tint_white;
    }
    &-game {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      padding: 14px 0 0;
      background: @tint_white;
      border-bottom: 4px solidvar(--bg-bg2);
      &_title {
        margin: 0 16px;
      }
      &_content {
        ul {
          display: flex;
          flex-wrap: wrap;
          li {
            cursor: pointer;
            width: 90px;
            height: 90px;
            // text-align: center;
            flex-shrink: 0;
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            .game-icon {
              width: 40px;
              height: 40px;
              background: 50% / contain no-repeat;
              margin: 0 auto;
            }
            .game-title {
              text-align: center;
              color: rgba(31, 33, 40, 0.6);
            }
          }
        }
      }
    }
    &-offer {
      :deep(.c-home-cell) {
        cursor: pointer;
        background: @tint_white;
        border-bottom: 1px solid #e8e9eb;
        padding-left: 16px;
      }
    }
  }

  .c-loading, .c-loading-more {
    padding: 20px 0;
    text-align: center;
  }

  &.modal-page,&.pc-page {
    :deep(.c-home-cell) {
      &:hover {
        opacity: 0.8;
      }
    }
  }

  &.pc-page {
    :deep(.c-scrollbar__wrap) {
      max-height: none;
    }
    padding-bottom: 50px;
    // border-bottom: none;
    .c-result-game {

      border-bottom: 8px solidvar(--bg-bg2);

    }
    .c-search-content.offer {
      background-color: var(--bg-bg2);

      .c-result {
        &_title {
          border-bottom: none;
          background: @tint_dark_1;
          margin: 0;
          padding-left: 16px;
        }
        &-offer {
          display: grid;
          // grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 8px;
          margin-top: 8px;
          :deep(.c-home-cell) {
            margin: 0;
            padding-right: 16px;
            border-bottom: none;
            max-width: 394px;
          }
        }
      }
    }

  }

  &.pc-page {
    height: 100%;
  }

  &.mobile-page {
    height: calc(100% - 40px);
  }

  &.pc-page {
    height: calc(100% - 80px);
  }

  &.mobile-page, &.pc-page {
    :deep(.c-scrollbar) {
      height: 100%;
    }
    :deep(.c-scrollbar__wrap) {
      max-height: none;
    }
  }
}
</style>
