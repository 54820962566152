import { generateUrl as generateUrl2 } from './utils';

export default {
  // 获取discord code码
  getAccountDiscordCode() {
    return generateUrl2('/api/account/discord_bind_code', false);
  },
  // 获取用户基础信息
  getAccountUserInfo() {
    return generateUrl2('/api/account/info', false);
  },
  getAnnouncement() {
    // return generateUrl2('/api/market/goods/sell_order', false);
    return generateUrl2('/api/account/announcement/get', false);
  },
  // 设置能否还价
  // postAllowBuyerBargain() {
  //   return generateUrl('/account/api/prefer/allow_buyer_bargain', false);
  // },
  // 设置是否展示店铺
  // postAllowStoreDisplay() {
  //   return generateUrl('/account/api/prefer/allow_shop_display', false);
  // },
  // 获取消息列表
  getMessages() {
    return generateUrl2('/api/account/notice/list', false);
  },
  // 获取店铺信息
  getUserStoreInfo() {
    return generateUrl2('/api/market/user_store/info', false);
  },
  // 变更店铺状态
  // changeUserStoreState() {
  //   return generateUrl('/api/market/user_store/change_state', false);
  // },
  // 修改头像
  postChangeProfilePic() {
    return generateUrl2('/api/account/profile_pic/modify', false);
  },
  // 修改昵称
  postChangeNickname() {
    return generateUrl2('/api/account/nickname/modify', false);
  },
  getAccountAssetBrief() {
    return generateUrl2('/api/v1/asset/get_brief_asset', false);
  },
  getAccountLoginStatus() {
    return generateUrl2('/api/account/login_check', false);
  },
  getMessageNotification() {
    return generateUrl2('/api/account/unread_badge', false);
  },
  getMessageNotificationRead() {
    return generateUrl2('/api/account/notice/read', false);
  },
  getMessageAnnouncement() {
    return generateUrl2('/api/account/marquee/get', false);
  },
  getMessageBanner() {
    return generateUrl2('/api/account/banner/get', false);
  },
  loginEmailCheck() {
    return generateUrl2('/api/account/email/check', false);
  },
  // postAccountRegisterConfirm() {
  //   return generateUrl('/account/api/register/confirm', false);
  // },
  postAccountEmailSendAuthcode() {
    return generateUrl2('/api/account/email/bind_send', false);
  },
  postAccountEmailVerifyAuthcode() {
    return generateUrl2('/api/account/email/bind', false);
  },
  postAccountAppLogout() {
    return generateUrl2('/api/account/logout', false);
  },
  // postMarketSteamTradeUrl() {
  //   return generateUrl('/api/market/steam/trade_url', false);
  // },
  // postAccountSteamApiKey() {
  //   return generateUrl('/account/api/steam_api_key_raw', false);
  // },
  // 获取帮助中心标题
  // getHelpInfo() {
  //   return generateUrl('/api/index/pc/help', false);
  // },
  // 获取帮助中心标题
  // getKnivesoutHelpInfo() {
  //   return generateUrl('/api/index/knivesout/help', false);
  // },
  // 获取帮助中心详情
  // getHelpInfoDetail() {
  //   return generateUrl('/api/index/help/detail', false);
  // },
  // 设置是否邮件通知
  // postAllowEmail() {
  //   return generateUrl('/account/api/prefer/allow_mail_notification', false);
  // },
  // 设置是否显示敏感内容
  // postAllowSensitiveControl() {
  //   return generateUrl('/account/api/prefer/allow_sensitive_content_control', false);
  // },
  // 获取饰品关注列表
  getBookMarkSellOrderList() {
    return generateUrl2('/api/account/bookmark/sell_order', false);
  },
  // 获取收藏饰品列表
  getBookMarkGoodsList() {
    return generateUrl2('/api/account/bookmark/goods', false);
  },
  // getFeedbackGenToken(isLogin) {
  //   if (isLogin) {
  //     return generateUrl('/api/feedback/gen_token', false);
  //   }
  //   return generateUrl('/api/feedback/unlogin_gen_token', false);
  // },
  // 获取钱包交易历史
  getBalanceFlowLog() {
    return generateUrl2('/api/v1/asset/asset/log', false);
  },
  // 获取钱包充值历史
  getBalanceRechargeLog() {
    return generateUrl2('/api/v1/asset/recharge/list', false);
  },
  // 获取钱包提现历史
  getBalanceWithdrawLog() {
    return generateUrl2('/api/v1/asset/withdraw/list', false);
  },
  // 充值预校验
  postRechargePreview() {
    return generateUrl2('/api/v1/asset/recharge/preview', false);
  },
  getForterSite() {
    return generateUrl2('/api/v1/asset/forter/site', false);
  },
  // PayPal网页充值
  // postRechargePaypalPage() {
  //   return generateUrl('/api/asset/recharge_paypal_page/', false);
  // },
  // cnp网页充值
  // postRechargeCnpPage() {
  //   return generateUrl('/api/asset/recharge_cnp_page/', false);
  // },
  // checkout网页充值
  postRechargeCheckoutPage() {
    return generateUrl2('/api/v1/asset/recharge/checkout/request', false);
  },
  // checkout充值绑卡
  postCheckoutSourceCreate() {
    return generateUrl2('/api/v1/asset/recharge/checkout/source/create', false);
  },
  // checkout绑卡删除
  postCheckoutSourceDelete() {
    return generateUrl2('/api/v1/asset/recharge/checkout/source/delete', false);
  },
  // 查询充值来源绑定状态
  getCheckoutSourceDetail() {
    return generateUrl2('/api/v1/asset/recharge/checkout/source/verify', false);
  },
  getLLPayToken() {
    return generateUrl2('/api/v1/asset/recharge/lianlianpay/iframe_token', false);
  },
  postLLPayRechargeRequest() {
    return generateUrl2('/api/v1/asset/recharge/lianlianpay/card/request', false);
  },
  postLLPayKonbiniRechargeRequest() {
    return generateUrl2('/api/v1/asset/recharge/lianlianpay/konbini/request', false);
  },
  postLLPayPayeasyRechargeRequest() {
    return generateUrl2('/api/v1/asset/recharge/lianlianpay/payeasy/request', false);
  },
  postPaymentwallRechargeRequest() {
    return generateUrl2('/api/v1/asset/recharge/paymentwall/request', false);
  },
  getAdyenRechargePayMethods() {
    return generateUrl2('/api/v1/asset/recharge/adyen/pay_methods', false);
  },
  postAdyenCardSourceDelete() {
    return generateUrl2('/api/v1/asset/recharge/adyen/source/delete', false);
  },
  postAdyenRechargeRequest() {
    return generateUrl2('/api/v1/asset/recharge/adyen/card/request', false);
  },
  postAdyenCardRechargeRequest() {
    return generateUrl2('/api/v1/asset/recharge/adyen/stored_card/request', false);
  },
  postAdyenMomoRechargeRequest() {
    return generateUrl2('/api/v1/asset/recharge/adyen/momo/request', false);
  },
  postPaypalRechargeRequest() {
    return generateUrl2('/api/v1/asset/recharge/paypal/request', false);
  },
  // 获取Boleto充值订单详情
  // getBoletoRechargeDetail() {
  //   return generateUrl('/api/asset/recharge_detail/', false);
  // },
  // Boleto网页充值
  // postRechargeBoletoPage() {
  //   return generateUrl('/api/asset/recharge_boleto_page/', false);
  // },
  // Boleto网页充值
  // postRechargeRazerPage() {
  //   return generateUrl('/api/asset/recharge_razer_pay_page/', false);
  // },
  // 获取提现相关信息
  getAssetWithdrawInfo() {
    return generateUrl2('/api/v1/asset/withdraw/info', false);
  },
  // 获取提现手续费
  getAssetWithdrawFee() {
    return generateUrl2('/api/v1/asset/withdraw/fee', false);
  },
  getBeneficiaryFields() {
    return generateUrl2('/api/v1/asset/withdraw/airwallex/form_info', false);
  },
  getUpdateBeneficiaryFields() {
    return generateUrl2('/api/v1/asset/withdraw/airwallex/beneficiary/form_info', false);
  },
  getBankList() {
    return generateUrl2('/api/v1/asset/withdraw/airwallex/bank_info', false);
  },
  getBeneficiaryList() {
    return generateUrl2('/api/v1/asset/withdraw/airwallex/beneficiary/list', false);
  },
  postCreateBeneficiary() {
    return generateUrl2('/api/v1/asset/withdraw/airwallex/beneficiary/create', false);
  },
  postUpdateBeneficiary() {
    return generateUrl2('/api/v1/asset/withdraw/airwallex/beneficiary/update', false);
  },
  postWithdrawAirwallex() {
    return generateUrl2('/api/v1/asset/withdraw/airwallex/request', false);
  },
  // Payoneer账户提现
  postWithdrawPayoneer() {
    return generateUrl2('/api/v1/asset/withdraw/payoneer/request', false);
  },
  // Payoneer银行卡提现
  postWithdrawPayoneerCard() {
    return generateUrl2('/api/v1/asset/withdraw/payoneer/card/request', false);
  },
  // 获取登陆Payoneer账号的url
  getPayoneerLoginUrl() {
    return generateUrl2('/api/v1/asset/withdraw/payoneer/register', false);
  },
  getBeneficiaryFieldsPayoneer() {
    return generateUrl2('/api/v1/asset/withdraw/payoneer/form_info', false);
  },
  getUpdateBeneficiaryFieldsPayoneer() {
    return generateUrl2('/api/v1/asset/withdraw/payoneer/beneficiary/form_info', false);
  },
  postCreateBeneficiaryPayoneer() {
    return generateUrl2('/api/v1/asset/withdraw/payoneer/beneficiary/card/create', false);
  },
  postUpdateBeneficiaryPayoneer() {
    return generateUrl2('/api/v1/asset/withdraw/payoneer/beneficiary/card/update', false);
  },
  postDeleteBeneficiary(method) {
    return generateUrl2(`/api/v1/asset/withdraw/${method}/beneficiary/delete`, false);
  },
  postWithdrawAmazonSendCode() {
    return generateUrl2('/api/v1/asset/withdraw/amazon/send_code', false);
  },
  postWithdrawAmazonCheck() {
    return generateUrl2('/api/v1/asset/withdraw/amazon/check', false);
  },
  postWithdrawAmazon() {
    return generateUrl2('/api/v1/asset/withdraw/amazon/request', false);
  },
  postWithdrawPaypal() {
    return generateUrl2('/api/v1/asset/withdraw/paypal/request', false);
  },
  getWithdrawAmazonDetail() {
    return generateUrl2('/api/v1/asset/withdraw/amazon/detail', false);
  },
  // 创建账单信息
  // postCreateBillInfo() {
  //   return generateUrl('/api/asset/bill_info/create', false);
  // },
  // 获取充值信息
  getRechargeInfo() {
    return generateUrl2('/api/v1/asset/recharge/info', false);
  },
  // 获取充值手续费
  getRechargeFee() {
    return generateUrl2('/api/v1/asset/recharge/fee', false);
  },
  // 充值订单状态查询
  getRechargeStateCheck() {
    return generateUrl2('/api/v1/asset/recharge/check', false);
  },
  getPayMethodList() {
    return generateUrl2('/api/v1/asset/pay/pay_method', false);
  },
  getPayMethodListCart() {
    return generateUrl2('/api/v1/asset/pay/cart_pay_method', false);
  },
  getCashierPayMethodList() {
    return generateUrl2('/api/market/goods/cashier/pay_method', false);
  },
  getCashierPayMethodListCart() {
    return generateUrl2('/api/market/goods/cashier/cart_pay_method', false);
  },
  postPaymentwallPayRequest() {
    return generateUrl2('/api/v1/asset/pay/paymentwall/request', false);
  },
  postCheckoutPayRequest() {
    return generateUrl2('/api/v1/asset/pay/checkout_card/request', false);
  },
  postLLPayKoibiniRequest() {
    return generateUrl2('/api/v1/asset/pay/lianlianpay/konbini/request', false);
  },
  postGlobalAliPayRequest() {
    return generateUrl2('/api/v1/asset/pay/global_alipay/request', false);
  },
  postPayPalPayRequest() {
    return generateUrl2('/api/v1/asset/pay/paypal/request', false);
  },
  postPaypalSourceDelete() {
    return generateUrl2('/api/v1/asset/pay/paypal/source/delete', false);
  },
  postLLPayCardRequest() {
    return generateUrl2('/api/v1/asset/pay/lianlianpay/card/request', false);
  },
  postAdyenRequest() {
    return generateUrl2('/api/v1/asset/pay/adyen/card/request', false);
  },
  postAdyenCardRequest() {
    return generateUrl2('/api/v1/asset/pay/adyen/stored_card/request', false);
  },
  postAdyenPaysafecardRequest() {
    return generateUrl2('/api/v1/asset/pay/adyen/paysafecard/request', false);
  },
  postApplePayPaymentSession() {
    return generateUrl2('/api/v1/asset/recharge/apple_pay/payment_session', false);
  },
  postApplePayRequest() {
    return generateUrl2('/api/v1/asset/pay/apple_pay/request', false);
  },
  postPingPongCardRequest() {
    return generateUrl2('/api/v1/asset/pay/pingpong/card/request', false);
  },
  postPingPongQiwiRequest() {
    return generateUrl2('/api/v1/asset/pay/pingpong/qiwi/request', false);
  },
  postDukpayRequest() {
    return generateUrl2('/api/v1/asset/pay/dukpay/request', false);
  },
  postTripleAPayRequest() {
    return generateUrl2('/api/v1/asset/pay/triple_a/request', false);
  },
  postRecordLimitedBuy() {
    return generateUrl2('/api/v1/asset/pay/record_limited_buy', false);
  },
  // BasisID认证获取api form token
  // postBasisidToken() {
  //   return generateUrl('/api/asset/basisid/token/', false);
  // },
  // Basisid 认证结果
  // getBasisidState() {
  //   return generateUrl('/api/asset/basisid/state/', true);
  // },
  // BasisID认证校验(前端收到event callback后调用)
  // postBasisidVerify() {
  //   return generateUrl('/api/asset/basisid/verify/', false);
  // },
  // KYC 认证结果
  getKycState() {
    return generateUrl2('/api/account/cert/info', false);
  },
  // Kyc认证获取跳转链接
  postKycGetUrl() {
    return generateUrl2('/api/account/cert/request', false);
  },
  // 获取支持货币列表
  getSupportedCurrency() {
    return generateUrl2('/api/account/get_currency', false);
  },
  // 设置BUFF价格货币
  postPreferCurrency() {
    return generateUrl2('/api/account/set_currency', false);
  },
  getSupportedSubgames() {
    return generateUrl2('/api/account/get_subgames', false);
  },
  // 获取收藏店铺列表
  getAccountBookmarkShop() {
    return generateUrl2('/api/account/bookmark/shops', false);
  },
  postAccountPendingLogin() {
    return generateUrl2('/api/account/pending_login', false);
  },
  // 邮箱注册申请
  postAccountEmailRegSend() {
    return generateUrl2('/api/account/email_reg/send', false);
  },
  // 邮箱注册信息检查
  getAccountEmailRegCheck() {
    return generateUrl2('/api/account/email_reg/check', false);
  },
  // 邮箱注册
  postAccountEmailReg() {
    return generateUrl2('/api/account/email_reg', false);
  },
  // 邮箱登录
  postAccountEmailLogin() {
    return generateUrl2('/api/account/email/login', false);
  },
  // 邮箱重设密码申请
  postAccountEmailResetSend() {
    return generateUrl2('/api/account/email_reset/send', false);
  },
  // 邮箱重设密码信息检查
  getAccountEmailResetCheck() {
    return generateUrl2('/api/account/email_reset/check', false);
  },
  // 邮箱重设密码
  postAccountEmailReset() {
    return generateUrl2('/api/account/email_reset', false);
  },
  // 设置邮箱密码
  postAccountEmailSetPassword() {
    return generateUrl2('/api/account/email/set_password', false);
  },
  // Facebook登录
  postFacebookLogin() {
    return generateUrl2('/api/account/facebook/login', false);
  },
  // Facebook绑定
  postFacebookBind() {
    return generateUrl2('/api/account/facebook/bind', false);
  },
  // Google登录
  postGoogleLogin() {
    return generateUrl2('/api/account/google/login', false);
  },
  // Google绑定
  postGoogleBind() {
    return generateUrl2('/api/account/google/bind', false);
  },
  // Apple登录
  postAppleLogin() {
    return generateUrl2('/api/account/apple/login', false);
  },
  // Apple绑定
  postAppleBind() {
    return generateUrl2('/api/account/apple/bind', false);
  },
  getTwitterLoginRequest() {
    return generateUrl2('/api/account/twitter/request', false);
  },
  // Twitter登录
  postTwitterLogin() {
    return generateUrl2('/api/account/twitter/login', false);
  },
  // Twitter绑定
  postTwitterBind() {
    return generateUrl2('/api/account/twitter/bind', false);
  },
  // Line登录
  postLineLogin() {
    return generateUrl2('/api/account/line/login', false);
  },
  // Line绑定
  postLineBind() {
    return generateUrl2('/api/account/line/bind', false);
  },
  // Tiktok登录
  postTikTokLogin() {
    return generateUrl2('/api/account/tiktok/login', false);
  },
  // Tiktok绑定
  postTiktokBind() {
    return generateUrl2('/api/account/tiktok/bind', false);
  },
  // VK登录
  postVkLogin() {
    return generateUrl2('/api/account/vk/login', false);
  },
  // VK绑定
  postVkBind() {
    return generateUrl2('/api/account/vk/bind', false);
  },
  // 单次有效token登录
  postOneTimeTokenLogin() {
    return generateUrl2('/api/account/one_time_token/login', false);
  },
  // Steam绑定验证
  // getSteamBindVerify() {
  //   return generateUrl('/account/api/bind_steam/openid_verifier', false);
  // },
  // Steam绑定确认
  // postSteamBindConfirm() {
  //   return generateUrl('/account/api/bind_steam/confirm', false);
  // },
  // 绑定第三方游戏角色
  postPartnerRoleBind() {
    return generateUrl2('/api/account/bind_role', false);
  },
  // 解绑第三方游戏角色
  postPartnerRoleUnbind() {
    return generateUrl2('/api/account/unbind_role', false);
  },
  // 解绑第三方游戏角色
  getPartnerRoleUnbindCheck() {
    return generateUrl2('/api/account/unbind_role_check', false);
  },
  // 获取第三方动态验证码
  getPartnerDynamicCode() {
    return generateUrl2('/api/account/get_bind_role_url', false);
  },
  // 获取支持语言
  getSupportedLanguage() {
    return generateUrl2('/api/account/get_language', false);
  },
  // 设置偏好语言
  postPreferLanguage() {
    return generateUrl2('/api/account/set_language', false);
  },
  getAccountUserInfoByShareKey() {
    return generateUrl2('/api/account/get_share_key_info', false);
  },
  postPartnerRoleBindByShareKey() {
    return generateUrl2('/api/account/share_bind_role', false);
  },
  getAccountToken() {
    return generateUrl2('/api/account/get_fp_token', false);
  },
  getFeedbackToken() {
    return generateUrl2('/api/account/feedback/get_token', false);
  },
  getCountryCode() {
    return generateUrl2('/api/account/get_country', false);
  },
  getUploadImageToken() {
    return generateUrl2('/api/account/get_fp_token', false);
  },
  postUnBindApply() {
    return generateUrl2('/api/account/retrieve/apply_role_retrieve', false);
  },
  sendEmailCode() {
    return generateUrl2('/api/account/retrieve/send_email_code', false);
  },
  applyAccountRetrieve() {
    return generateUrl2('/api/account/retrieve/apply_account_retrieve', false);
  },
  denyAccountRetrieve() {
    return generateUrl2('/api/account/retrieve/deny_account_retrieve', false);
  },
  denyRoleRetrieve() {
    return generateUrl2('/api/account/retrieve/deny_role_retrieve', false);
  },
  getAccountEmail() {
    return generateUrl2('/api/account/retrieve/account_retrieve_info', false);
  },
  postAccountFcmRegistToken() {
    return generateUrl2('/api/account/fcm/regist_token', false);
  },
  getPromoteAffTop() {
    return generateUrl2('/api/promote/aff_top', false);
  },
  getPromoteAffStat() {
    return generateUrl2('/api/promote/aff_stat', false);
  },
  postPromoteEnableAff() {
    return generateUrl2('/api/promote/enable_aff', false);
  },
  getPromoteAffOrderList() {
    return generateUrl2('/api/promote/aff_order_list', false);
  },
  postPointTaskSubmit() {
    return generateUrl2('/api/point/submit_task', false);
  },
  getPointLog() {
    return generateUrl2('/api/point/log', false);
  },
  getPointGiftList() {
    return generateUrl2('/api/point/gift_list', false);
  },
  postPointExchange() {
    return generateUrl2('/api/point/exchange', false);
  },
  getPromoteGetCustomAppService() {
    return generateUrl2('/api/promote/get_custom_app_service', false);
  },
  getPromoteGetActivity() {
    return generateUrl2('/api/promote/get_activity', false);
  },
  postPromoteCustomAff() {
    return generateUrl2('/api/promote/custom_aff', false);
  },
  postPromoteJoinAffActivity() {
    return generateUrl2('/api/promote/join_aff_activity', false);
  },
  getPromoteAffUserList() {
    return generateUrl2('/api/promote/aff_user_list', false);
  },
  postTpGenInviteLink() {
    return generateUrl2('/api/tp/gen_invite_link', false);
  },
  getPromoteInviteUserPromoteDetail() {
    return generateUrl2('/api/promote/invite_user/promote_detail', false);
  },
  getPromoteTaskList() {
    return generateUrl2('/api/promote/task_list', false);
  },
  postPromoteTaskSubmit() {
    return generateUrl2('/api/promote/submit_task', false);
  },
  getPromoteInviteUserList() {
    return generateUrl2('/api/promote/invite_user_list', false);
  },
};
