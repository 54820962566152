import { GOODSTYPE, TABNAME } from '@/enums';

const accounts = require('@/assets/images/icon_function_accounts.png');
const cdk = require('@/assets/images/icon_function_cdk.png');
const items = require('@/assets/images/icon_function_items.png');
const topup = require('@/assets/images/icon_function_topup.png');
const coin = require('@/assets/images/icon_function_coin.png');
const coinHot = require('@/assets/images/coin_hot.png');
const sbc = require('@/assets/images/img_sbc_search.png');

export default {
  [TABNAME.TOPUP]: {
    id: GOODSTYPE.TOPUP,
    name: 'lootBar.game_up',
    tabName: TABNAME.TOPUP,
    icon: topup,
  },
  [TABNAME.COINTRADE]: {
    id: GOODSTYPE.COINTRADE,
    name: 'coin.coin_trade',
    tabName: TABNAME.COINTRADE,
    icon: coin,
    hot: coinHot,
  },
  [TABNAME.ASSET]: {
    id: GOODSTYPE.ASSET,
    name: 'lootBar.game_items',
    tabName: TABNAME.ASSET,
    icon: items,
  },
  [TABNAME.ITEMTRADE]: {
    id: GOODSTYPE.ITEMTRADE,
    name: 'lootBar.game_items',
    tabName: TABNAME.ASSET,
    icon: items,
  },
  [TABNAME.ACCOUNT]: {
    id: GOODSTYPE.ACCOUNT,
    name: 'lootBar.accounts',
    tabName: TABNAME.ACCOUNT,
    icon: accounts,
  },
  [TABNAME.CDKEY]: {
    id: GOODSTYPE.CDKEY,
    name: 'lootBar.cd-key',
    tabName: TABNAME.CDKEY,
    icon: cdk,
  },
  [TABNAME.SBC]: {
    id: GOODSTYPE.SBC,
    name: 'sbc.title',
    tabName: TABNAME.SBC,
    icon: sbc,
  },
};
